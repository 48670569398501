import { OptionError } from '../OptionError';
import { isBlank } from '../utils/isBlank';
import { isFormula } from '../utils/isFormula';
import { typeCast } from '../utils/typeCast';
import { validateFormula } from '../utils/validateFormula';
import { GridOption } from './GridOption';

/**
 * Class representing a "Data" GRID element option.
 * This option expects a formula or multi cell reference as its value. Used to provide data to output elements.
 * @augments GridOption
 */
export class DataOption extends GridOption {
  type = 'data';

  /** @param {import('./GridOption').GridOptionArgs} opts */
  constructor (opts) {
    super(opts);
    if (opts.enforceExpression == null) {
      this.enforceExpression = true;
    }
  }

  /**
   * Validate that the value is either blank or a valid formula.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @return {OptionError|null} Error object or null if valid.
   */
  validate (value) {
    if (isBlank(value)) {
      return null;
    }
    // TODO: parse range expression using Apiary
    //   if valid expression: OKAY
    //   if has area: <2 WARNING
    //   if formula: validateFormula
    //   else: ERROR
    const str = String(value);
    if (isFormula(str)) {
      return validateFormula(str);
    }
    return new OptionError();
  }

  /**
   * @param {string|number|boolean|null|undefined} value
   * @param {string} [locale]
   * @return {string | number | boolean | null}
   */
  parse (value, locale) {
    return typeCast(value, locale);
  }

  /**
   * @param {object} props - A props collection to read from.
   * @return {import('@/grid/types').CellArray} A nested array of cells
   */
  readCropped (props) {
    return this._readModelCells(props, true);
  }

  readFilled (props) {
    const data = this._readModelCells(props, false);
    // Because of the way Apiary works, the resulting data-table may still not
    // be the same size as the range (because of sheet boundaries and *fully*
    // blank cells). Thus, we pad the table as needed.
    const expectedHeight = (data.bottom || 0) - (data.top || 0) + 1;
    const expectedWidth = (data.right || 0) - (data.left || 0) + 1;
    // Size limitations are enforced here for safety, it's quite easy for
    // a user to add A:B and lock the client up in an unusable document
    const missingHeight = Math.min(1000, expectedHeight - data.length);
    const missingWidth = Math.min(100, expectedWidth - data[0].length);
    if (missingHeight > 0 || missingWidth > 0) {
      // inject columns into cells that are already there
      if (missingWidth) {
        for (let i = 0; i < data.length; i++) {
          while (data[i].length < expectedWidth) {
            // cell must be a unique object!
            data[i].push({ v: null });
          }
        }
      }
      // add missing rows
      if (missingHeight) {
        for (let i = data.length; i < expectedHeight; i++) {
          data[i] = [];
          while (data[i].length < expectedWidth) {
            // cell must be a unique object!
            data[i].push({ v: null });
          }
        }
      }
    }
    return data;
  }

  /**
   * Read the cell array referenced or constructed by the formula in the props.
   * If the raw value is not a formula and not blank, wrap it in a 2D array.
   * If blank, returns an empty 2D array.
   *
   * @param {object} props - A props collection to read from.
   * @return {import('@/grid/types').CellArray} The option value or its default value.
   */
  read = props => {
    return this._readModelCells(props, false);
  };
}
