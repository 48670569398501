/**
 * Determines whether the specified option value is a valid Excel formula
 * expression. (CURRENTLY A NO-OP)
 *
 * @param {string} formula An potential formula string
 * @return {import('@/grid/options/OptionError').OptionError | null}
 */
// eslint-disable-next-line no-unused-vars
export function validateFormula (formula) {
  // TODO: syntax validate through Excel parser
  // currently all formula are considered to be valid
  // const fxErr = null; // FIXME: should call to some actual validator
  // if (fxErr) {
  //   return new OptionError(fxErr.message, OptionError.ERROR, fxErr);
  // }
  return null;
}
