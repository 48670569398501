import { Element, TextNode } from './Node';

export function applyNodeFilter (dom, nodeFilter, preserveLayout) {
  if (nodeFilter.length === 0) {
    return dom;
  }

  const children = dom.children;
  const targetNodes = {}; // store references to targeted nodes

  const filteredChildren = [];
  for (const child of children) {
    let foundElementInRow = false;
    if (child.name !== 'row') {
      if (nodeFilter.includes(child.id)) {
        targetNodes[child.id] = child;
        filteredChildren.push(child);
        continue;
      }
    }
    // if a row is a target, we indiscriminately include the whole subtree
    else if (child.name === 'row' && nodeFilter.includes(child.id)) {
      foundElementInRow = true;
    }
    else if (child.name === 'row') {
      const cols = [];
      // if any of the cols has children, push all of them
      for (const col of child.children) {
        if (nodeFilter.includes(col.id)) {
          targetNodes[col.id] = col;
          foundElementInRow = true;
          cols.push(col);
          continue;
        }
        const blocks = [];
        for (const block of col.children) {
          if (nodeFilter.includes(block.id)) {
            targetNodes[block.id] = block;
            foundElementInRow = true;
            blocks.push(block);
          }
        }
        col.children = blocks;
        cols.push(col);
      }
      child.children = cols;
    }

    if (foundElementInRow) {
      if (!preserveLayout) {
        child.attr.size = 'full';
      }
      filteredChildren.push(child);
    }
  }

  // render error message if the target mixes element types
  // or if the target element type is something other than 'row' or 'grid:block'
  const targetTypes = new Set(nodeFilter.map(id => (targetNodes[id] ? targetNodes[id].name : null)));
  const singleTargetType = targetTypes.size === 1;
  const onlyAllowedTypes = !targetTypes.has('grid:inline');

  if (!singleTargetType || !onlyAllowedTypes) {
    const row = new Element('row', { size: 'full' });
    const col = row.appendChild(new Element('col', { size: '1/1' }));
    const paragraph = col.appendChild(new Element('p'));
    let message = 'cannot embed elements of different types';
    if (!onlyAllowedTypes) {
      message = 'invalid target element';
    }
    paragraph.appendChild(new TextNode(`Error: ${message}. Please contact support@calculatorstudio.co for more information.`));
    dom.children = [ row ];
    return;
  }

  // when blocks are selected, they are listed in a single container
  if (singleTargetType && targetTypes.has('grid:block')) {
    const row = new Element('row', { size: 'full' });
    const col = row.appendChild(new Element('col', { size: '1/1' }));
    nodeFilter.forEach(id => col.appendChild(targetNodes[id]));
    dom.children = [ row ];
    return;
  }

  if (singleTargetType && targetTypes.has('col')) {
    const row = new Element('row', { size: 'full' });
    nodeFilter.forEach(id => row.appendChild(targetNodes[id]));
    dom.children = [ row ];
    return;
  }

  dom.children = filteredChildren;
}
