/**
 * Determines whether an option value should be considered blank. GRID option
 * values are considered blank if they are `null`, `undefined`, blank string
 * (`""`), or a single equals sign (`"="`).
 *
 * @param {string|number|boolean|null|undefined} value An option value
 * @return {boolean} True if the specified value is considered blank, false otherwise.
 */
export function isBlank (value) {
  if (value === '' || value === '=' || value == null) {
    return true;
  }
  return false;
}
