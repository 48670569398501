import getID from '../utils/uid';
import { Element, InlineNode, MetaNode, TextNode } from './Node';

export function createDocument (message) {
  const body = new Element('body');
  const p = new Element('p');
  body.appendChild(p);
  if (message) {
    p.appendChild(new TextNode(message));
  }
  return body;
}

export const blockedTypes = new Set([
  'inlinePlaceholder',
  'hiddenParagraph',
  'p:hidden',
  'chartAssistant',
]);

export const blockTypes = new Set([
  'row',
  'col',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'ol',
  'ul',
  'hr',
  'slidebreak',
  'pagebreak',
  'quote',
  'code',
]);

export const inlineTypes = new Set([
  'mention',
]);

// exported for tests
export function jsonToNode (node) {
  const name = node.name;
  if (blockedTypes.has(name) || !name) {
    return null;
  }
  else if (name === 'meta') {
    return new MetaNode(node.type || '', node.value || '', node.id);
  }
  else if (inlineTypes.has(name)) {
    return new InlineNode(name, node.value, node.attr, node.id);
  }
  else if (name === 'text') {
    if (!node.value) {
      return null;
    }
    return new TextNode(node.value, node.attr, node.id);
  }
  const elm = new Element(name, node.attr, node.id);
  if (Array.isArray(node.children)) {
    node.children.forEach(d => {
      const child = jsonToNode(d);
      if (child) {
        elm.appendChild(child);
      }
    });
  }
  return elm;
}

export function parseJSON (jsonBody) {
  let raw = { name: 'body' };
  if (jsonBody) {
    try {
      raw = typeof jsonBody === 'string'
        ? JSON.parse(jsonBody)
        : jsonBody;
    }
    catch (err) {
      console.error(err);
    }
  }
  if (typeof raw !== 'object' || Array.isArray(raw)) {
    console.error('Invalid document format object');
    raw = { name: 'body' };
  }
  return jsonToNode(raw);
}

export function isEmpty (node) {
  if (!node.children || !node.children.length) {
    return true;
  }
  for (let i = 0; i < node.children.length; i++) {
    const child = node.children[i];
    const isMaybeText = child.type === 'text' || !child.type;
    if (blockedTypes.has(child.type) || (isMaybeText && child.text === '')) {
      // ingnored node or empty text
    }
    else {
      return false;
    }
  }
  return true;
}

function slateToGrid (node) {
  if (node.type === 'p:hidden' && !isEmpty(node)) {
    // the editor does not flip hidden paragraphs to unhidden on every keystroke
    // so if this hidden paragraph has content we "promote" it to a regular one
    node = { ...node, type: 'p' };
  }
  if (blockedTypes.has(node.type) || (!node.type && node.text === '')) {
    // omit this
    return null;
  }
  else if (node.object === 'text' || node.type === 'text' || !!node.text) {
    if (!node.text) {
      // ignore "empty" text nodes
      return null;
    }
    return new TextNode(node.text, {
      code: node.code,
      italic: node.italic,
      bold: node.bold,
    }, node.id);
  }
  else if (inlineTypes.has(node.type)) {
    return new InlineNode(node.type, node.value, node.data, node.id);
  }
  else if (
    node.type === 'grid:inline' ||
    node.type === 'grid:block' ||
    node.type === 'hr'
  ) {
    return new Element(node.type, node.data, node.id);
  }
  else if (blockTypes.has(node.type) || node.type === 'link') {
    const elm = new Element(node.type, node.data, node.id);
    if (Array.isArray(node.children)) {
      node.children.forEach(d => {
        const ch = slateToGrid(d);
        if (ch) {
          elm.appendChild(ch);
        }
      });
    }
    return elm;
  }
  else {
    // should never happen but if it does, we leave a trace
    console.error('Invalid node found', node);
  }
}

export function fromSlate (dom, bodyAttr, bodyId) {
  const body = new Element('body', bodyAttr, bodyId || getID());
  dom.forEach(node => {
    const n = slateToGrid(node);
    if (n) {
      body.appendChild(n);
    }
  });
  return body;
}
