import { errorTable } from '@grid-is/apiary/lib/excel/constants';

import { OptionError } from '../OptionError';
import { isBlank } from '../utils/isBlank';
import { isFormula } from '../utils/isFormula';
import { isValidUrl } from '../utils/isValidUrl';
import { normalizeHref } from '../utils/normalizeHref';
import { validateFormula } from '../utils/validateFormula';
import { StringOption } from './StringOption';

/**
 * Class representing a "URL" GRID element option.
 * Only URLs are considered valid and emitted through this value.
 * @augments StringOption
 */
export class UrlOption extends StringOption {
  type = 'url';

  /**
   * @param {string} value - An option value.
   * @return {boolean}
   */
  // eslint-disable-next-line no-unused-vars
  isPlainOption = value => {
    return false;
  };

  /**
   * Validate a raw value as being a valid formula, or a valid URL, or blank.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @return {OptionError|null} Error object or null if valid.
   */
  validate (value) {
    if (isBlank(value)) {
      return null;
    }
    const str = String(value);
    if (isFormula(str)) {
      // TODO: consider adding a warning here (esp. if not a single cell ref)?
      return validateFormula(str);
    }
    if (isValidUrl(str)) {
      return null;
    }
    return new OptionError();
  }

  /**
   * Parse a value as a URL with one of the allowed URL schemes.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @param {string} [locale] - A locale to use when parsing dates
   * @return {string} A normalized URL interpretable from `value`, else `""`.
   */
  // eslint-disable-next-line no-unused-vars
  parse (value, locale) {
    const str = String(value == null ? '' : value);
    // normalizeHref will rather liberally make a URL out of almost anything by
    // prepending a protocol. Let's not do that for formula errors like #VALUE!.
    if (errorTable[str]) {
      return '';
    }
    // we do not want to perform queries unless what we are holding is an actual URL
    return normalizeHref(str);
  }
}
