import EventEmitter from 'component-emitter';
import io from 'socket.io-client';

import { schemas } from './generatedTypes';
import { getApiUrl } from './request';

// TODO: provide type information for the events, use when initializing the socket.io client
// See: https://socket.io/docs/v4/typescript/

interface Message {
  key: string,
  payload: object,
}

export interface WorkbookInfo {
  documentId: string,
  workbookId: string,
  password?: string,
  shouldPing?: boolean,
}

export type WorkbookModified = schemas['WorkbookModified'];

// This is a subset of the socket.io client API, hence the name "SlimSocket"
type SlimSocket = {
  connected: boolean,
  on: (event: string, fn: (...args: any[]) => void) => SlimSocket,
  onAny: (fn: (event: string, ...args: any[]) => void) => SlimSocket,
  emit: (event: string, ...args: any[]) => SlimSocket,
}
type GetSocket = () => SlimSocket;

export class WebSocketClient extends EventEmitter {
  socket: SlimSocket | null;
  getSocket: GetSocket;

  constructor (getSocket: GetSocket = createSocketIOClient) {
    super();
    this.socket = null;
    this.getSocket = getSocket;
  }

  createSocket () {
    if (!this.socket) {
      this.socket = this.getSocket();
      this.socket.on('connect', this.onConnect);
      this.socket.onAny((event, ...args) => {
        let data = null;
        if (args.length === 1 && args[0].data) {
          data = JSON.parse(args[0].data);
        }
        this.emit(event, data);
      });
    }
  }

  onConnect = () => {
    if (this.socket?.connected) {
      this.emit('connect');
    }
  };

  watchWorkbook = (wbInfo: WorkbookInfo) => {
    this._send({ key: 'watch_workbook',
      payload: {
        document_id: wbInfo.documentId,
        workbook_id: wbInfo.workbookId,
        password: wbInfo.password,
      } });
  };

  pingWorkbook = (wbInfo: WorkbookInfo) => {
    this._send({
      key: 'ping_workbook',
      payload: {
        document_id: wbInfo.documentId,
        workbook_id: wbInfo.workbookId,
        password: wbInfo.password,
      },
    });
  };

  unwatchWorkbook (workbookId: string) {
    if (workbookId) {
      this._send({ key: 'unwatch_workbook',
        payload: {
          workbook_id: workbookId,
        } });
    }
  }

  watchDocument (documentId: string) {
    if (documentId) {
      this._send({ key: 'watch_document',
        payload: {
          document_id: documentId,
        } });
    }
  }

  unwatchDocument (documentId: string) {
    if (documentId) {
      this._send({ key: 'unwatch_document',
        payload: {
          document_id: documentId,
        } });
    }
  }

  _send (message: Message) {
    this.createSocket();
    if (this.socket?.connected) {
      this.socket.emit(message.key, message.payload);
    }
  }
}

export function createSocketIOClient (socketIO = io, getApiURL = getApiUrl) {
  const APIUrl = new URL(getApiURL());
  const socketioPath = APIUrl.pathname.endsWith('/') ? 'socket.io5/' : '/socket.io5/';
  return socketIO('wss://' + APIUrl.host + '/change-notifications', {
    path: APIUrl.pathname + socketioPath,
    transports: [ 'websocket' ],
  });
}

export const changeNotificationClient = new WebSocketClient();

