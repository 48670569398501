// Value is IETF BCP 47 language tag and label the language
export const languages = [
  { value: 'zh-CN', label: 'Chinese (Simplified)' },
  { value: 'zh-TW', label: 'Chinese (Traditional)' },
  { value: 'cs-CZ', label: 'Czech' },
  { value: 'da-DK', label: 'Danish' },
  { value: 'nl-NL', label: 'Dutch' },
  { value: 'en-GB', label: 'English (UK)' },
  { value: 'en-US', label: 'English (US)' },
  { value: 'fi-FI', label: 'Finnish' },
  { value: 'fr-FR', label: 'French' },
  { value: 'de-DE', label: 'German' },
  { value: 'el-GR', label: 'Greek' },
  { value: 'hu-HU', label: 'Hungarian' },
  { value: 'is-IS', label: 'Icelandic' },
  { value: 'id-ID', label: 'Indonesian' },
  { value: 'it-IT', label: 'Italian' },
  { value: 'ja-JP', label: 'Japanese' },
  { value: 'ko-KR', label: 'Korean' },
  { value: 'nb-NO', label: 'Norwegian (Bokmal)' },
  { value: 'pl-PL', label: 'Polish' },
  { value: 'pt-PT', label: 'Portuguese' },
  { value: 'ru-RU', label: 'Russian' },
  { value: 'sk-SK', label: 'Slovak' },
  { value: 'es-ES', label: 'Spanish' },
  { value: 'sv-SE', label: 'Swedish' },
  { value: 'th-TH', label: 'Thai' },
  { value: 'tr-TR', label: 'Turkish' },
];
