import stepNormalize from '@/grid/utils/stepNormalize';

import { OptionError } from '../OptionError';
import { isBlank } from '../utils/isBlank';
import { isFormula } from '../utils/isFormula';
import { isValidNumber } from '../utils/isValidNumber';
import { typeCast } from '../utils/typeCast';
import { validateFormula } from '../utils/validateFormula';
import { GridOption } from './GridOption';

/**
 * Class representing a "Numeric" GRID element option.
 * The value is validated as, and cast to, a number
 *
 * @augments GridOption
 */
export class NumberOption extends GridOption {
  type = 'number';

  /**
   * @param {import('./GridOption').GridNumberOptionArgs} props The properties
   */
  constructor (props) {
    super(props);
    /** @type {string} */
    this.format = props.format || 'General';
    /** @type {number|undefined} */
    this.min = props.min;
    /** @type {number|undefined} */
    this.max = props.max;
    /** @type {number|undefined} */
    this.step = props.step;
  }

  /**
   * Validate as a number.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @return {OptionError|null} Error object or null if valid.
   */
  validate (value) {
    if (isBlank(value)) {
      return null;
    }
    const str = String(value);
    if (isFormula(str)) {
      return validateFormula(str);
    }
    if (isValidNumber(str) || str.toLowerCase() === 'auto') {
      return null;
    }
    return new OptionError();
  }

  /**
   * @param {string} value - An option value.
   * @param {string} [locale] - A locale to use when parsing dates
   * @return {boolean}
   */
  // eslint-disable-next-line no-unused-vars
  isPlainOption = (value, locale) => {
    if (isBlank(value)) {
      return true;
    }
    if (isFormula(value) || !isValidNumber(value)) {
      return false;
    }
    const num = +(typeCast(value, locale) || 0);
    if (!isFinite(num)) {
      return false;
    }
    // boundary check: min >= value => max
    if ((this.min != null && num < this.min) ||
        (this.max != null && num > this.max)) {
      return false;
    }
    // Note: step is not checked (on purpose)
    return true;
  };

  /**
   * Parse a value as a number.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @param {string} [locale] - A locale to use when parsing dates
   * @return {(number|null)} The number that this value can be interpreted as, or null.
   */
  parse (value, locale) {
    // xxx: Min and Max use "auto" as a default value for historical reasons
    //      We should consider fixing and migrating this to placeholders.
    if (isBlank(value) || /^auto$/i.test(String(value))) {
      return null;
    }
    const num = +(typeCast(value, locale) || 0);
    return isFinite(num)
      ? stepNormalize(num, this.min, this.max, this.step)
      : null;
  }

  /**
   * Read an option value and parse it as a number.
   * @param {object} props - A props collection to read from.
   * @return {number|null} The number interpretable from the value or its formula result, else null.
   */
  read = props => {
    const cell = this.readCell(props);
    return this.parse(cell ? cell.v : null);
  };

  /**
   * Read an option value and parse it as a number.
   * @param {object} props - A props collection to read from.
   * @param {number} maxValue - The total to calculate a percentage of.
   * @param {number|null} decimals - How many decimals should the number be rounded to.
   * @return {number|null} The number interpretable from the value or its formula result, else null.
   */
  readAsPercentOfMax = (props, maxValue = 1, decimals = 2) => {
    const percent = this.read(props);
    if (percent != null) {
      const part = Math.max(0, Math.min(1, percent)) * maxValue;
      const m = 10 ** (decimals || 0);
      return decimals != null
        ? Math.round(part * m) / m
        : part;
    }
    return null;
  };
}
