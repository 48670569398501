/**
 * Determines whether an option value looks like an Excel formula (string
 * starting with `=`), whether it is valid or not.
 *
 * @param {unknown} value An option value
 * @return {boolean} True if `value` looks like it is intended to be a formula, else false.
 */
export function isFormula (value) {
  return typeof value === 'string' && value[0] === '=';
}
