import { Reference } from '@grid-is/apiary';
import { errorForCode } from '@grid-is/apiary/lib/excel/constants';
import { CellValue } from '@grid-is/apiary/lib/excel/types';
import { isBoxed } from '@grid-is/apiary/lib/excel/ValueBox';
import { isCellValue } from '@grid-is/apiary/lib/typeguards';
import { ModelStateTree } from '@grid-is/apiary/lib/types';
import { b64decode, b64encode } from '@grid-is/base64';
import { assert } from '@grid-is/validation';

export type ModelStateArray = (readonly [string, CellValue])[];

export function toObject (modelState: ModelStateArray): ModelStateTree {
  const state: ModelStateTree = {};
  for (const [ refStr, value ] of modelState) {
    const ref = Reference.parse(refStr);
    if (ref == null) {
      console.error('Unable to parse reference', refStr);
      continue;
    }
    const cellName = ref.name || String(ref.range);
    state[ref.workbookName] = state[ref.workbookName] || {};
    state[ref.workbookName][ref.sheetName] = state[ref.workbookName][ref.sheetName] || {};
    state[ref.workbookName][ref.sheetName][cellName] = value;
  }
  return state;
}

export function printObject (modelState: ModelStateTree): string {
  return b64encode(JSON.stringify(modelState), '-_');
}

// utility to transform given modelState to dense object format (instead of flat array) and print it
export function print (modelState: ModelStateArray): string {
  const state = toObject(modelState);
  return printObject(state);
}

function unsafeParse (printedModelState: string): ModelStateArray {
  const state = JSON.parse(b64decode(printedModelState, '-_'));
  const items: ModelStateArray = [];
  if (Array.isArray(state)) {
    for (const [ refName, jsonValue ] of state) {
      const value = toCellValue(jsonValue);
      if (typeof value !== 'undefined') {
        items.push([ refName, value ]);
      }
    }
  }
  else {
    // convert the object representation into array
    for (const workbookName of Object.keys(state)) {
      for (const sheetName of Object.keys(state[workbookName])) {
        for (const cellName of Object.keys(state[workbookName][sheetName])) {
          const refName = String(Reference.from(cellName, { sheetName: sheetName, workbookName: workbookName }));
          const value = toCellValue(state[workbookName][sheetName][cellName]);
          if (typeof value !== 'undefined') {
            items.push([ refName, value ]);
          }
        }
      }
    }
  }
  return items;
}

function toCellValue (value: unknown): CellValue | undefined {
  if (isCellValue(value)) {
    assert(!isBoxed(value));
    return value;
  }
  if (typeof value === 'object' && value != null && 'code' in value && typeof value.code === 'number') {
    const error = errorForCode(value.code);
    if (error && 'detail' in value && typeof value.detail === 'string') {
      return error.detailed(value.detail);
    }
  }
  return undefined;
}

// parse supports both object serialized format
// and the previous array format
export function parse (printedModelState: string): ModelStateArray | null {
  if (printedModelState) {
    try {
      const modelState = unsafeParse(printedModelState);
      return modelState.length ? modelState : null;
    }
    catch (err) {
      console.error(err);
    }
  }
  return null;
}

const ignoreSearchKeys = {
  ssr: true,
  showcontrols: true,
  stats: true,
  usergroups: true,
  ref: true,
  p: true,
  preserveLayout: true,
  print_mode: true,
  notification_channel: true,
  auto_request_access: true,
  notification_type: true,
  cloud_drive_id: true,
  template: true,
  background: true,
};

// either parse "?s=" or params that can be parsed as valid ranges (named ranges not supported)
// and all the values are primitives (numbers, booleans or strings)
export function parseFromQuery (query: string | null | Record<string, string>): ModelStateArray | null {
  if (query) {
    if (typeof query === 'object' && query.s) {
      return parse(query.s);
    }
    else {
      const searchParams = new URLSearchParams(query);
      const potentialModelState: ModelStateArray = [];
      searchParams.forEach((value, key) => {
        // ignore utm parameters in search for ranges, and ignore userGroups, ssr and stats
        if (key.toLowerCase().startsWith('utm_') || ignoreSearchKeys[key.toLowerCase()]) {
          return;
        }
        const ref = Reference.parse(key);
        if (ref && ref.isA1) {
          potentialModelState.push([ key, typeCast(value) ]);
        }
        else {
          console.error('Unable to parse reference', key);
        }
      });
      return potentialModelState.length ? potentialModelState : null;
    }
  }
  return null;
}

// copied from https://github.com/GRID-is/g-lang/blob/95da351403ea3ca0eeda1938e8581add45046938/lib/index.js#L45-L68
function typeCast (str: string): CellValue {
  // boolean
  const lcStr = str.toLowerCase();
  if (lcStr === 'true') {
    return true;
  }
  if (lcStr === 'false') {
    return false;
  }
  // number
  if (/^-?(\d*\.\d+|\d+)([Ee][+-]?\d+)?$/.test(str)) {
    const n = Number(str);
    if (isFinite(n)) {
      return n;
    }
  }
  // everything else is a string
  return unesc(str);
}

function unesc (str: string): string {
  if (str.length > 1 && str[0] === '"' && str[str.length - 1] === '"') {
    return str.slice(1, -1).replace(/""/g, '"');
  }
  return str;
}
