export function extractTitle (text: string, maxLength = 60): string {
  let result = text.trim();

  // Trim leading periods
  result = result.replace(/^\.+/, '');

  // Check if the paragraph contains a period followed by whitespace or end of string
  // But don't start searching from the very beginning of the string, because it might start with 1. or E.g. or similar
  const minLength = 10;
  if (result.length > minLength) {
    const periodIndex = result.slice(minLength).search(/\.(\s|$)/);
    if (periodIndex !== -1) {
      // Extract the substring before the period
      result = result.slice(0, minLength + periodIndex);
    }
  }

  // Check if the paragraph length exceeds the limit
  if (result.length > maxLength) {
    // Find the last word boundary within the maxLength
    const lastWordBoundary = result.lastIndexOf(' ', maxLength);

    if (lastWordBoundary !== -1) {
      // Cut off the paragraph at the last word boundary and append '...'
      result = result.slice(0, lastWordBoundary) + '...';
    }
    else {
      // If no word boundary found, cut off the remaining characters
      result = result.slice(0, maxLength - 3) + '...';
    }
  }

  return result;
}
