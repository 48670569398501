// @ts-nocheck
import { translateToA1 } from '@borgar/fx';
import { getFormatInfo, parseValue } from 'numfmt';

import { FormulaError } from '@grid-is/apiary';

import StyleDeduper from './StyleDeduper.js';

export function sortCells (data) {
  const cells = Array.from(data);
  // sort cells by ID to make lookups
  cells.sort((a, b) => {
    if (!a.id || !b.id) {
      return NaN;
    }
    const [ , aC, aR ] = /^(\D+)(\d+)$/.exec(a.id);
    const [ , bC, bR ] = /^(\D+)(\d+)$/.exec(b.id);
    if (bR !== aR) { // row first
      return (aR < bR) ? -1 : 1;
    }
    // then col
    return (aC < bC) ? -1 : 1;
  });
  return cells;
}

function getFormatType (z) {
  const formatInfo = getFormatInfo(z);
  let type = 'number';
  if (formatInfo.isDate) {
    type = 'date';
  }
  else if (formatInfo.isPercent) {
    type = 'percent';
  }
  else if (formatInfo.isText) {
    type = 'text';
  }
  return type;
}

export function prepCellFormatString (value, existingNumberFormat) {
  // need to improve formula handling & locale.
  // 1. if all referenced ranges have the same format pattern then use that (e.g. =sum(A1, B2) where A1 & B2 have z='0%' then use it as the format)
  // 2. handle date formulas, e.g. =today()
  if (value == null) {
    return existingNumberFormat;
  }

  if (typeof value === 'boolean' || value instanceof FormulaError) {
    return null;
  }
  const parsedVal = parseValue(value);
  let newNumberFormat = parsedVal?.z;
  if (
    existingNumberFormat && (
      !newNumberFormat ||
      getFormatType(existingNumberFormat) === getFormatType(newNumberFormat)
    )
  ) {
    // if there is already number formatting set on the cell
    // only override the value if the numfmt type has changed e.g. from bool -> date;
    newNumberFormat = existingNumberFormat;
  }
  return newNumberFormat ?? null;
}

// expects an Apiary Workbook class instance as input
export function prepWorkbookSavedata (workbook, setResetState = false) {
  if (setResetState) {
    workbook.resetModel();
  }

  const deduper = new StyleDeduper();
  const sheets = workbook.getSheets().map(sheet => {
    const outSheet = {
      name: sheet.name,
      cells: {},
      columns: sheet.columns
        ? JSON.parse(JSON.stringify(sheet.columns))
        : undefined,
    };
    // TODO: might be lighter to get a list of used addresses from engine?
    const cells = sortCells(sheet.getCells());

    const spillRangeToAnchor = {};
    cells.forEach(cell => {
      if (spillRangeToAnchor[cell.F]) {
        // Already found anchor for spill range.
        return;
      }
      if (!cell.F || !cell.f) {
        // Not an anchor cell.
        //
        // A valid spill anchor cell should have a formula (see CLICKUP-4460).
        return;
      }

      let id = cell.F.split(':')[0];
      if (/^\d+$/.test(id)) {
        // Row selection (1:1, 12:14)
        id = 'A' + id;
      }
      else if (/^[A-Z]+$/i.test(id)) {
        // Column selection (A:A, AB:AC)
        id = id + '1';
      }

      if (cell.id === id) {
        spillRangeToAnchor[cell.F] = cell;
      }
    });
    const spillAnchorExistsForRange = fProp => !!spillRangeToAnchor[fProp];

    cells.forEach(cell => {
      const outCell = {};
      if (cell.f) {
        outCell.f = cell.f;
        outCell.v = cell.v;
      }
      if (cell.F && spillAnchorExistsForRange(cell.F)) {
        outCell.F = cell.F;
      }
      if (cell.v != null) {
        outCell.v = cell.v;
      }
      const si = deduper.getSi(cell);
      if (si) {
        outCell.si = si;
      }
      if (outCell.v instanceof Error) {
        outCell.v = String(cell.v);
      }
      // only save cells that have content
      if (Object.keys(outCell).length) {
        outSheet.cells[cell.id] = outCell;
      }
    });
    return outSheet;
  });
  // NOTE: if you are adding defined names to the workbook save data, remember
  // to exclude dynamic formula defined-name objects (those with IDs starting
  // with the `DYNAMIC_FORMULA_CELL_ID_PREFIX` defined in Apiary), as those
  // should not be persisted.
  return {
    sheets: sheets,
    styles: deduper.getStyles(),
  };
}

/**
 * Mutates cellData from clipboard. Converts formulas from RC to A1 as needed and
 * nukes the value stored cells that either contain a formula or are spilled
 *
 * @export
 * @param {CellData} cellData
 * @param {string} a1: string representation of a single cell. the cell to
 */
export function prepPastedCellData (oldCellData, a1) {
  const cellData = Object.assign({}, oldCellData);
  if (cellData.f && cellData.isRC) {
    // Some formulas will use RC address mode for references and will need
    // to be converted to A1 when the pasting position is known.
    // If the formula is RC then it's isRC parameter will be true, else it
    // will be false.
    cellData.f = translateToA1(cellData.f, a1, { wrapEdges: false, xlsx: true });
  }

  // Removing the value causes Apiary to recalculate the cell, this could be done
  // only for formulas that have references but has to be done for all reference
  // formulas because they may point to cell that don't exist in the target sheet
  // (such as if they were copied from Google Sheets)
  // TODO: in "paste values"-mode we should be clearing the formula but not the value
  if (cellData.f) {
    cellData.v = null;
  }

  // don't write a value when the cell contains a spilled value
  if (cellData.spilled) {
    cellData.v = null;
  }
  return cellData;
}
