import { Reference } from '@grid-is/apiary';

import { OptionError } from '../OptionError';
import { isBlank } from '../utils/isBlank';
import { GridOption } from './GridOption';

/**
 * Class representing a "Reference" GRID element option.
 * This option expects a single or area range reference as its value.
 * @augments GridOption
 */
export class ReferenceOption extends GridOption {
  type = 'reference';

  /** @param {import('./GridOption').GridOptionArgs} opts */
  constructor (opts) {
    super(opts);
    if (opts.enforceExpression == null) {
      this.enforceExpression = true;
    }
  }

  /**
   * Validate a value as being a reference expression with a `=` in front of it, or blank.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @return {OptionError|null} Error object or null if valid.
   */
  validate (value) {
    if (isBlank(value)) {
      return null;
    }
    const str = String(value);
    if (str.startsWith('=')) {
      const refData = Reference.parse(str.slice(1).trim());
      if (refData && refData.isA1) {
        return null;
      }
    }
    return new OptionError();
  }

  /**
   * Parse a value as an A1-style cell or range reference.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @return {Reference|null} A reference, if the value can be interpreted as one, else null.
   */
  parse (value) {
    const strValue = value ? String(value) : '';
    if (value && /^=/.test(strValue)) {
      const ref = Reference.from(strValue.slice(1).trim());
      if (ref && ref.range) {
        return ref;
      }
    }
    return null;
  }

  /**
   * Read an option value and parse it as a cell or range reference.
   * @param {object} props - A props collection to read from.
   * @param {boolean} [allowNonA1=false] - Should non-A1 type references be returned?
   * @return {(Reference|null)} The option value or its default value.
   */
  read = (props, allowNonA1 = false) => {
    const propValue = this.readRaw(props);
    if (propValue && props.model) {
      try {
        const ref = propValue && props.model.runFormula(propValue);
        if (ref instanceof Reference && (ref.isAddress || allowNonA1)) {
          return ref;
        }
      }
      catch (err) {
        // Parser will throw if the reference is invalid
      }
    }
    return null;
  };
}
