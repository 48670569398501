/**
 * The minimum chart width required for value labels to be shown.
 * This is set to a comfortable size for the Pie chart, which is the most
 * tolerant one after the unstacked Bar chart (which can tolerate very
 * narrow spacing). We need a consistent value for all charts because they
 * all change this on resize which looks strange if they are different.
 */
export const VALUE_LABELS_MIN = 300;

/**
 * The time to delay before committing input values to a cell write.
 * When the value is obviously text, we perform an immediate write, but
 * if the value is a number we defer the writes so that users don't see
 * charts thrash about while typing large numbers.
 */
export const TYPING_DELAY = 450;

/**
 * The shortest frequency, in seconds, a timer is allowed to run at.
 * Set to 60 Hz.
 */
export const MIN_TIMER_INTERVAL = 1 / 60;

/**
 * When an action button needs to show an animation, we need to show it
 * for this minimum time period so users register what has happened.
 * Set to a second in milliseconds.
 */
export const MIN_BUTTON_ANIM_TIME = 1000;

/**
 * The maximum number of cells a table is allowed to render. Performance
 * really starts to suffer past a certain point so we need a cap for this.
 */
export const MAX_TABLE_CELLS = 65536;

/** The minimum font size allowed in a document. */
export const MIN_FONT_SIZE = 8;

/** The maximum font size allowed in a document. */
export const MAX_FONT_SIZE = 150;

/** The minimum font size allowed in a document button. */
export const MIN_BUTTON_FONT_SIZE = 10;

/** The maximum font size allowed in a document button.*/
export const MAX_BUTTON_FONT_SIZE = 50;

/**
 * "textcolor" is a color keyword that instead of defining a specific color value
 * instructs a renderer to use the current color of the document text.
 * @type {string}
 */
export const TEXTCOLOR = 'textcolor';

/**
 * "accentcolor" is a color keyword that instead of defining a specific color value
 * instructs a renderer to use the accent color of the document.
 * @type {string}
 */
export const ACCENTCOLOR = 'accentcolor';
/**
 * "backgroundcolor" is a color keyword that instead of defining a specific color value
 * instructs a renderer to use the current backcound color of the element.
 * @type {string}
 */
export const BACKGROUNDCOLOR = 'backgroundcolor';
