// @ts-nocheck
import getID from '../utils/uid';

function isObject (a) {
  return Object.prototype.toString.call(a) === '[object Object]';
}

const blockedAttr = [ 'error', 'subType', 'id', 'shouldMerge' ];
function cloneAttr (src, blacklist = blockedAttr) {
  const copy = {};
  isObject(src) && Object.keys(src).forEach(key => {
    if (blacklist.includes(key)) {
      return;
    }
    let value = src[key];
    if (isObject(value)) {
      // recurse down options-groups (`axisDim { title: "foo" }`)
      value = cloneAttr(value);
      // if we end up with an empty object (user removed last item from group)
      if (value && !Object.keys(value).length) {
        // don't save the group
        value = null;
      }
    }
    // null, undefined, "", "=" all mean "unset" in grid options
    if (value != null && value !== '' && value !== '=') {
      copy[key] = value;
    }
  });
  return copy;
}

export class Node {
  constructor (name, attr, id) {
    this.name = name;
    this.attr = cloneAttr(attr);
    this.id = id;
  }

  normalize () {
    if (this.children?.length) {
      this.children.forEach(d => d.normalize());
    }
  }

  toSlate () {
    const children = this.children.map(d => d.toSlate());
    return {
      object: 'block',
      id: this.id,
      type: this.name,
      data: cloneAttr(this.attr),
      children: children.length ? children : [ { text: '' } ],
    };
  }

  toGrid () {
    const n = { name: this.name, id: this.id };
    const attr = Object.keys(this.attr);
    if (attr.length) {
      n.attr = cloneAttr(this.attr);
    }
    if (this.children.length) {
      n.children = this.children.map(d => d.toGrid());
    }
    return n;
  }

  hasContent () {
    return true;
  }

  find (predicate) {
    if (predicate(this)) {
      return this;
    }
    if (this.children) {
      for (const child of this.children) {
        const found = child.find(predicate);
        if (found) {
          return found;
        }
      }
    }
  }
}

export class TextNode extends Node {
  constructor (value, attr, id = getID()) {
    super('text', {
      italic: attr && attr.italic ? true : null,
      bold: attr && attr.bold ? true : null,
      code: attr && attr.code ? true : null,
    }, id);
    this.value = value || '';
  }

  toSlate () {
    const n = {
      object: 'text',
      id: this.id,
      text: this.value || '',
    };
    if (this.attr.italic) {
      n.italic = this.attr.italic;
    }
    if (this.attr.bold) {
      n.bold = this.attr.bold;
    }
    if (this.attr.code) {
      n.code = this.attr.code;
    }
    return n;
  }

  toGrid () {
    // don't store this.id in text nodes – they're tricky to manage when nodes split/merge
    const n = { name: this.name, value: this.value || '' };
    if (Object.keys(this.attr).length) {
      n.attr = cloneAttr(this.attr);
    }
    return n;
  }

  hasContent () {
    // Has something other than a whitespace
    return this.value && /\S/.test(this.value);
  }
}

export class MetaNode extends Node {
  /**
   * @param {string} type
   * @param {string} value
   * @param {string} [id=getID()]
   */
  constructor (type, value, id = getID()) {
    super('meta', {}, id);
    this.type = type;
    this.value = value;
  }

  // xxx: add support for MetaNodes to editor
  toSlate () {
    // MetaNodes are cleaned out of the DOM before we hand it to Slate
    // but foc completeness, and in case they make their way in we
    // reduce them to empty text
    return { object: 'text', id: this.id, text: '' };
  }

  toGrid () {
    return { name: 'meta', type: this.type, value: this.value };
  }

  hasContent () {
    // Has something other than a whitespace
    return this.value && /\S/.test(this.value);
  }
}

export class InlineNode extends Node {
  constructor (type, value, attr, id = getID()) {
    super(type, null, id);
    this.attr = cloneAttr(attr);
    this.value = value ?? null;
  }

  toSlate () {
    return {
      object: 'block',
      key: this.id,
      type: this.name,
      value: this.value,
      data: cloneAttr(this.attr),
      children: [ { text: '' } ],
    };
  }

  toGrid () {
    const n = { name: this.name, id: this.id, value: this.value };
    const attr = Object.keys(this.attr);
    if (attr.length) {
      n.attr = cloneAttr(this.attr);
    }
    return n;
  }
}

export class Element extends Node {
  constructor (name, attr, id = getID()) {
    super(name, attr, id);
    this.children = [];
  }

  get firstChild () {
    return this.children[0];
  }

  isTextBlock () {
    return /^(h[1-6]|ol|ul|quote|code|p)$/.test(this.name);
  }

  normalize () {
    if (!this.children.length && this.isTextBlock()) {
      this.appendChild(new TextNode(''));
    }
    super.normalize();
  }

  appendChild (child) {
    this.children.push(child);
    return child;
  }

  removeChild (child) {
    this.children = this.children.filter(d => d !== child);
  }

  insertBefore (child, refNode) {
    const refIndex = refNode ? this.children.indexOf(refNode) : -1;
    if (refIndex < 0) {
      this.children.push(child);
    }
    else {
      this.children.splice(refIndex, 0, child);
    }
    return child;
  }

  insertAfter (child, refNode) {
    const refIndex = refNode ? this.children.indexOf(refNode) : -1;
    if (refIndex < 0) {
      this.children.push(child);
    }
    else {
      this.children.splice(refIndex + 1, 0, child);
    }
    return child;
  }

  replaceChild (newChild, oldChild) {
    this.insertBefore(newChild, oldChild);
    this.removeChild(oldChild);
  }

  hasContent () {
    // grid element is content
    if (this.name && this.name.startsWith('grid:')) {
      return true;
    }
    // singleton elements are considered content
    if (this.name === 'pagebreak' || this.name === 'slidebreak' || this.name === 'hr') {
      return true;
    }
    return this.children.some(node => node.hasContent());
  }
}

export function visit (node, callback) {
  callback(node);
  if (node.children) {
    for (let i = 0; i < node.children.length; i++) {
      visit(node.children[i], callback);
    }
  }
}
