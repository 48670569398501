import { typeCast } from '../utils/typeCast';
import { GridOption } from './GridOption';

/**
 * @typedef {import('@/grid-ui/Select/types').SelectOptionType} Option
 */

/**
 * Class representing a GRID element option.
 */
export class ValueOption extends GridOption {
  /** @type {string} */
  type = 'any';

  /**
   * No-op for this option, all values are valid.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @return {null} Error object or null if valid.
   */
  // eslint-disable-next-line no-unused-vars
  validate (value) {
    return null;
  }

  /**
   * Parse a value by applying general typecasting conventions and optionally a given locale.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @param {string} [locale] - A locale to use when parsing dates
   * @return {(boolean|string|number|Reference|null)} The most reasonable representation of the value.
   */
  parse (value, locale) {
    return typeCast(value, locale);
  }

  /**
   * Read an option value and parse it with general typecasting conventions.
   * @param {object} props - A props collection to read from.
   * @return {boolean|string|number|Reference|null} The option value.
   */
  read = props => {
    const cell = this.readCell(props);
    return this.parse(cell ? cell.v : null, props.locale);
  };
}
