import { dateToSerial } from 'numfmt';

import { RE_DATE, RE_ISODATE, RE_TIME } from './isValidNumber';
import { unesc } from './unesc';

/**
 * Parse a option value string and return its value in the appropriate JS type.
 *
 * @param {unknown} value An option value
 * @param {string} [locale='en-US'] A locale to use for parsing dates
 * @return {(boolean|string|number|null)} The cast value
 */
export function typeCast (value, locale = 'en-US') {
  if (value == null) {
    return null;
  }
  let str = String(value);
  // excel expression
  if (str[0] === '=') {
    return str;
  }
  // boolean
  const lcStr = str.trim().toLowerCase();
  if (lcStr === 'true' || lcStr === 'false') {
    return lcStr === 'true';
  }
  // number
  if (/^-?(\d*\.\d+|\d+)([Ee][+-]?\d+)?%?$/.test(str)) {
    let mult = 1;
    if (str.endsWith('%')) {
      str = str.slice(0, -1);
      mult = 0.01;
    }
    const n = Number(str) * mult;
    if (isFinite(n)) {
      return n;
    }
  }
  // ISO date/datetime: YYYY-MM-DD | YYYY-MM-DDTHH:MM:SS
  const m1 = RE_ISODATE.exec(str);
  if (m1) {
    const datetime = dateToSerial([
      +m1[1], +m1[2], +m1[3],
      +m1[4], +m1[5], +m1[6],
    ]);
    if (typeof datetime === 'number' && isFinite(datetime)) {
      return datetime;
    }
  }
  // date/datetime: DD/MM/YYYY | DD/MM/YYYY HH:MM:SS | MM/DD/YYYY | MM/DD/YYYY HH:MM:SS
  const m2 = RE_DATE.exec(str);
  if (m2) {
    let year = +m2[3];
    if (m2[3].length < 4) {
      if (year < 30) { // 0-29 => 20yy
        year += 2000;
      }
      else if (year < 100) { // 30-99 => 19yy
        year += 1900;
      }
    }
    const datetime = locale === 'en-US'
      ? dateToSerial([ year, +m2[1], +m2[2], +m2[4], +m2[5], +m2[6] ])
      : dateToSerial([ year, +m2[2], +m2[1], +m2[4], +m2[5], +m2[6] ]);
    if (typeof datetime === 'number' && isFinite(datetime)) {
      return datetime;
    }
  }
  // time only: HH:MM | HH:MM:SS
  const m3 = RE_TIME.exec(str);
  if (m3) {
    const time = dateToSerial([ 1900, 1, 1, +m3[1], +m3[2], +m3[3] ]);
    if (typeof time === 'number' && isFinite(time)) {
      return time % 1;
    }
  }
  // everything else is a string
  return unesc(str);
}
