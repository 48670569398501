export const RE_ISODATE = /^(\d{4})-(\d\d)-(\d\d)(?:[ T](\d\d):(\d\d)(?::(\d\d))?)?$/;
export const RE_DATE = /^(\d\d?)\/(\d\d?)\/(\d\d(?:\d\d)?)(?: +([0-2]?\d):([0-5]\d)(?::([0-5]\d))?)?$/;
export const RE_TIME = /^([0-2]?\d):([0-5]\d)(?::([0-5]\d))?$/;

/**
 * Determines whether a specified string is a valid number, percentage, date, or time.
 *
 * @param {string} value An option value
 * @return {boolean}
 */
export function isValidNumber (value) {
  if (/^-?(\d*\.\d+|\d+)([Ee][+-]?\d+)?%?$/.test(value)) {
    if (value.endsWith('%')) {
      value = value.slice(0, -1);
    }
    return isFinite(Number(value));
  }
  if (RE_ISODATE.test(value) || RE_DATE.test(value) || RE_TIME.test(value)) {
    return true;
  }
  return false;
}
