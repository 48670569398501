/**
 * Determines whether the specified option value appears to be a valid HTTP/HTTPS URL.
 *
 * @param {string} value An option value
 * @return {boolean} True if the specified value is a URL, false otherwise.
 */
export function isValidUrl (value) {
  // xxx: harden this to use native URL class.
  if (/^(?:https?:)?\/\/\S/i.test(value)) {
    return true;
  }
  return false;
}
