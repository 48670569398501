import { OptionError } from '../OptionError';
import { isBlank } from '../utils/isBlank';
import { isFormula } from '../utils/isFormula';
import { isValidColor } from '../utils/isValidColor';
import { validateFormula } from '../utils/validateFormula';
import { GridOption } from './GridOption';

/**
 * Class representing a single "Color" GRID element option.
 * Values are expected to be a qualified HTML color name or a 3 or 6 digit hex
 * color code (`#C43` or `#CD4534`). If it isn't, the option wil not validate
 * and the parsed value will be an empty string.
 *
 * @augments GridOption
 */
export class ColorOption extends GridOption {
  type = 'color';

  isPlainOption = value => {
    return isBlank(value) || isValidColor(value, true);
  };

  /**
   * Read an option value and parse it as a color value.
   * @param {object} props - A props collection to read from.
   * @return {string} A string representing a color, or else an empty string.
   */
  read = props => {
    const cell = this.readCell(props);
    return this.parse(cell ? cell.v : null);
  };

  /**
   * Validate a value as a color name or code.
   * @param {any} value - An option value.
   * @return {OptionError|null} Error object or null if valid.
   */
  validate (value) {
    if (isBlank(value)) {
      return null;
    }
    const str = String(value);
    if (isFormula(str)) {
      return validateFormula(str);
    }
    if (isValidColor(str)) {
      return null;
    }
    return new OptionError();
  }

  /**
   * Parse a value as a color name or code.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @return {string} A string representing a color, or else an empty string.
   */
  parse (value) {
    if (!value || isBlank(value)) {
      return '';
    }
    const strValue = String(value).toLowerCase();
    if (value && isValidColor(strValue, true)) {
      return strValue;
    }
    return '';
  }
}
