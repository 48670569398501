export class OptionList {
  type = 'list';

  /**
   * Create an OptionList.
   * @param {object} attr - Attributes of the option.
   * @param {string} attr.name - the name of the option, this should correspond to what gets saved to props. (e. "title").
   * @param {Record<string, import('./GridOption').GridOption>} attr.shape - unique id of the option (e. "title-label").
   * @param {string} [attr.label] - human readable title of the option.
   * @param {import('react').ReactNode} [attr.help] - human readable help text for the option.
   * @param {import('react').ReactNode} [attr.tooltip]
   * @param {string} [attr.placeholder] - text to show if there is no value present.
   */
  constructor ({
    name, label, tooltip,
    shape, placeholder, help,
  }) {
    if (!shape) {
      throw new Error('OptionList is missing a shape');
    }
    /** @type {string} */
    this.name = name;
    /** @type {Record<string, import('./GridOption').GridOption>} */
    this.shape = shape;
    /** @type {string} */
    this.label = label || '';
    /** @type {import('react').ReactNode} */
    this.help = help || null;
    /** @type {import('react').ReactNode} */
    this.tooltip = tooltip || '';
    /** @type {string | undefined} */
    this.placeholder = placeholder;
  }

  /**
   * @param {object} props - A props collection to read from.
   * @return {boolean} true if the optionlist has an item.
   */
  isSet (props) {
    const value = props[this.name];
    if (!Array.isArray(value)) {
      return false;
    }
    // workaround for an editor bug where empty object sit around in the array
    if (value.length === 1 && !Object.keys(value[0]).length) {
      return false;
    }
    return value.length > 0;
  }

  /**
   * Read an option value from a props object and deal with running any spreadsheet formula as needed.
   * @param {object} props - A props collection to read from.
   * @return {Record<string, any>[]} The option value or its default value.
   */
  read = props => {
    const value = props[this.name];
    const { model, locale } = props;
    /** @type {Record<string, any>[]} */
    const ret = [];
    if (!Array.isArray(value)) {
      return ret;
    }
    for (const item of value) {
      /** @type {Record<string, any>} */
      const o = {};
      let values = 0;
      for (const [ key, handler ] of Object.entries(this.shape)) {
        const propValue = item[handler.name] ?? handler.defaultValue;
        if (propValue != null && propValue !== '') {
          o[key] = handler.read({ model, locale, ...item });
          values++;
        }
      }
      // trim out all items that don't have values set
      if (values) {
        ret.push(o);
      }
    }
    return ret;
  };

  /**
   * @param {object} props - A props collection to read from.
   * @return {Record<string, import('@/grid/types').Cellish>[]} The option value or its default value.
   */
  readCell = props => {
    const value = props[this.name];
    const { model, locale } = props;
    /** @type {Record<string, import('@/grid/types').Cellish>[]} */
    const ret = [];
    if (!Array.isArray(value)) {
      return ret;
    }
    for (const item of value) {
      /** @type {Record<string, import('@/grid/types').Cellish>} */
      const o = {};
      let values = 0;
      for (const [ key, handler ] of Object.entries(this.shape)) {
        const propValue = item[handler.name] ?? handler.defaultValue;
        if (propValue != null && propValue !== '') {
          o[key] = handler.readCell({ model, locale, ...item });
          values++;
        }
      }
      // trim out all items that don't have values set
      if (values) {
        ret.push(o);
      }
    }
    return ret;
  };
}
