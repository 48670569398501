import { BLANK_CELL } from '@grid-is/apiary/lib/excel/Cell';

import { printCell } from '@/grid/utils';

import { isFormula } from '../utils/isFormula';
import { validateFormula } from '../utils/validateFormula';
import { GridOption } from './GridOption';

/**
 * Class representing a "String" GRID element option.
 * Its value is cast to a string.
 * @augments GridOption
 */
export class StringOption extends GridOption {
  type = 'string';

  /**
   * Validate that the raw value is either a valid formula or does not start with a `=`.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @return {import('../OptionError').OptionError | null} Error object or null if valid.
   */
  validate (value) {
    const str = String(value);
    // TODO: if this looks like an expression /^[a-z]+\d+(:[a-z]+\d+)?$/ but doesn't have a = then issue a warning
    if (isFormula(str)) {
      return validateFormula(str);
    }
    return null;
  }

  /**
   * Stringify the value, including mapping `null` to an empty string.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @return {string} The most reasonable representation of the value.
   */
  parse (value) {
    return value == null ? '' : String(value);
  }

  /**
   * Read an option value and convert it to a string.
   * @param {object} props - A props collection to read from.
   * @return {string} The option value or its default value.
   */
  read = props => {
    // current value or default
    let propValue = this.readRaw(props);
    // Formula and references should be passed through the model
    if (isFormula(propValue)) {
      // if this is a formula and there is no model, don't emit a value
      const cell = props.model
        ? props.model.readCell(propValue)
        : BLANK_CELL;
      propValue = printCell(cell, undefined, props.locale);
    }
    return this.parse(propValue);
  };
}
