import { assert } from '@grid-is/validation';

function fixCase (d) {
  return d.toLowerCase().replace(/-([a-z])/g, (a, b) => {
    return b.toUpperCase();
  });
}

/**
 * @param {string} source
 * @returns {Map}
 */
export function parseStyles (source) {
  // discard comments
  source = source.replace(/\/\*[^\0]*?\*\//g, a => ' '.repeat(a.length));

  const rules = new Map();
  const selector = [];
  try {
    let lastPos = 0;
    for (let pos = 0; pos < source.length; pos++) {
      const char = source[pos];
      // end declaration
      if (char === ';') {
        assert(pos > lastPos, 'Unexpected ;');
        const content = source.slice(lastPos, pos);
        selector
          .join(' ')
          .split(/\s*,\s*/)
          .forEach(s => {
            const key = s.trim();
            const rule = rules.get(key) || {};
            const m = /^\s*([a-z-]+)\s*:\s+(\S.*)\s*$/.exec(content);
            assert(m);
            if (m) {
              rule[fixCase(m[1])] = m[2];
            }
            rules.set(key, rule);
          });
        lastPos = pos + 1;
      }
      // open block
      else if (char === '{') {
        assert(pos > lastPos, 'Unexpected {');
        selector.push(source.slice(lastPos, pos));
        lastPos = pos + 1;
      }
      // close block
      else if (char === '}') {
        assert(selector.length, 'Unexpected }');
        selector.pop();
        lastPos = pos + 1;
      }
    }
  }
  catch (err) {
    // styles are invalid - unset them
    rules.clear();
  }

  return rules;
}
