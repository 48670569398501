type Task<T> = () => Promise<T>;

export class SequentialWorker {
  private queue: Task<void>[] = [];
  private running = false;

  public async enqueue<T> (task: Task<T>): Promise<T> {
    const [ promise, wrappedTask ] = this.createPromiseForTask(task);
    this.queue.push(wrappedTask);
    this.getToWork().catch(err => console.error(err));
    return promise;
  }

  private createPromiseForTask<T> (task: Task<T>): [Promise<T>, Task<void>] {
    let resolve: (value: T) => void;
    let reject: (err: unknown) => void;

    // This is a bit of a hack. Define an executor that only captures the resolve and reject functions.
    // This allows us to return a promise that is resolved or rejected by the task.
    const capturingExecutor = (res: (value: T) => void, rej: (err: unknown) => void) => {
      resolve = res;
      reject = rej;
    };

    const promise = new Promise<T>(capturingExecutor);

    // Wrap the original task in another that either resolves or rejects the returned promise.
    const wrappedTask = async () => {
      try {
        const result = await task();
        resolve(result);
      }
      catch (err) {
        reject(err);
      }
    };

    return [ promise, wrappedTask ];
  }

  private async getToWork () {
    if (!this.running) {
      this.running = true;
      let task: Task<void> | undefined;
      while ((task = this.queue.shift()) !== undefined) {
        // eslint-disable-next-line no-await-in-loop
        await task();
      }
      this.running = false;
    }
  }
}
