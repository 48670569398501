function roundByStep (value, base, step) {
  return base + (step * Math.round((value - base) / step));
}

// stepping is done with a low-fidelity to reduce floating point artifacts
function lofi (value) {
  return +value.toFixed(13);
}

/**
 * Normalize a number to given min/max/step constraints.
 *
 * @param {number} value The value
 * @param {number} [min] The minimum allowed value
 * @param {number} [max] The maximum allowed value
 * @param {number} [step] The granularity of the value
 * @return {number} The normlized number
 */
export default function stepNormalize (value, min = undefined, max = undefined, step = undefined) {
  let minimum = -Infinity;
  let maximum = Infinity;
  let stepSize = 1;
  let stepBase = 0;
  let hasStep = false;

  // read min
  if ((min === 0 || min) && isFinite(min)) {
    minimum = +min;
    stepBase = minimum;
  }
  // read max
  if ((max === 0 || max) && isFinite(max)) {
    maximum = +max;
  }
  // read step
  if ((step === 0 || step) && isFinite(step)) {
    hasStep = true;
    stepSize = +step;
  }

  const inRangeValue = Math.max(minimum, Math.min(value, maximum));

  // if there is step, we need to ensure that the value is rounded to step
  if (hasStep) {
    const rounded = lofi(roundByStep(inRangeValue, stepBase, stepSize));
    let clamped;
    if (rounded > maximum) {
      clamped = rounded - stepSize;
    }
    else if (rounded < minimum) {
      clamped = rounded + stepSize;
    }
    else {
      clamped = rounded;
    }
    // BÞ: I don't think this can actually happen but html spec assumes it can
    if (clamped < minimum || clamped > maximum) {
      return lofi(inRangeValue);
    }
    return lofi(clamped);
  }

  // if there is no step we can just return the clamped value
  else {
    return lofi(inRangeValue);
  }
}
