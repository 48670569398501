import { isValidUrl } from './isValidUrl.js';

const rePathStart = /^(\/|\.\/|\.\.\/)[^/]/;
const reQueryStart = /^\?[a-z0-9%+~._-]/i; // allow query if seems to be followed by legit param
const reSafeProto = /^(http|https|ftp|sftp|mailto|tel):/;
const reUnsafeProto = /^(javascript|vbscript|data):/;
// xxx: fix this ReDOS-vulnerable expression plz
const reEmail = /^(?!\..*$)\S+@(?!\..*$)\S+\.\S+$/;

/**
 * Sanitizes a link href value so that it can be freely and safely used by a renderer.
 *
 * @param {string} href The href
 * @return {string} A normalized URL string
 */
export function normalizeHref (href) {
  if (typeof href !== 'string') {
    return '';
  }
  href = href.trim();
  if (href.length === 0) {
    return href;
  }
  // the following hrefs will be ignored as returned as-is
  // /about-us
  // ./yet-another-doc-OzXo9meeTTqS:gJAdNVAGQ
  // ../@another-user
  if (rePathStart.test(href) || reQueryStart.test(href)) {
    return href;
  }
  // detect safe protocols and return as-is
  if (reSafeProto.test(href) || isValidUrl(href)) {
    return href;
  }
  // detect unsafe protocols and return empty href
  if (reUnsafeProto.test(href)) {
    return '';
  }
  // if the href matches an email, prepend mailto:
  if (reEmail.test(href)) {
    return `mailto:${href}`;
  }
  // otherwise, prepend http://
  return `http://${href}`;
}
