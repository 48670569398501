import { OptionError } from '../OptionError';
import { isBlank } from '../utils/isBlank';
import { isFormula } from '../utils/isFormula';
import { validateFormula } from '../utils/validateFormula';
import { GridOption } from './GridOption';

/**
 * Class representing an "Enum" GRID element option, one that permits a specific
 * set of string values.
 *
 * Values are cast as they are read, but only considered valid if they are
 * present in the provided set of options.
 *
 * @augments GridOption
 */
export class EnumOption extends GridOption {
  type = 'enum';

  isPlainOption = value => {
    return isBlank(value) || (
      this.options.some(d => d.value === value || d.alias === value)
    );
  };

  /**
   * If the value matches one of the permitted values (allowing for case and
   * aliasing), then the canonical form of that permitted value is returned.
   * Else `this.defaultValue` is returned.
   *
   * @param {object} props - A props collection to read from.
   * @return {string} The option value or its default value.
   */
  read = props => {
    const cell = this.readCell(props);
    return this.parse(cell ? cell.v : null);
  };

  /**
   * Check that the value is one of the permitted values, and return it
   * normalized, else return the default value.
   *
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @return {string} The canonical form of a matching permitted value, or the default value.
   */
  parse (value) {
    const str = String(value ?? this.defaultValue)
      .toLowerCase()
      .trim();
    // value must exist in options
    const option = this.options.find(d => str === d.value || str === d.alias);
    if (option != null) {
      // emit the normalized (and un-aliased) value
      return option.value;
    }
    // if not, we emit the default value
    return this.defaultValue ?? '';
  }

  /**
   * Validate that the raw value is either a formula or one of the permitted values.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @return {OptionError|null} Error object or null if valid.
   */
  validate (value) {
    if (isBlank(value)) {
      return null;
    }
    const str = String(value);
    if (isFormula(str)) {
      return validateFormula(str);
    }
    // is this one of the selectable options?
    const opt = this.options.find(d => str === d.value || str === d.alias);
    if (opt) {
      return null;
    }
    return new OptionError();
  }
}
