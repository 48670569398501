import getID from '../utils/uid';
import { Element, TextNode, visit } from './Node';

const LEVEL_BODY = 1000;
const LEVEL_ROW = 500;
const LEVEL_COL = 100;
const LEVEL_GROUP = 50;
const LEVEL_BLOCK = 10;
const LEVEL_LINK = 5;
const LEVEL_INLINE = 1;

const levels = {
  'body': LEVEL_BODY,
  'meta': LEVEL_ROW,
  'row': LEVEL_ROW,
  'col': LEVEL_COL,
  'grid:block': LEVEL_BLOCK,
  'p': LEVEL_BLOCK,
  'p:hidden': LEVEL_BLOCK,
  'quote': LEVEL_BLOCK,
  'code': LEVEL_BLOCK,
  'h1': LEVEL_BLOCK,
  'h2': LEVEL_BLOCK,
  'h3': LEVEL_BLOCK,
  'h4': LEVEL_BLOCK,
  'h5': LEVEL_BLOCK,
  'h6': LEVEL_BLOCK,
  'ol': LEVEL_BLOCK,
  'ul': LEVEL_BLOCK,
  'hr': LEVEL_BLOCK,
  'slidebreak': LEVEL_BLOCK,
  'pagebreak': LEVEL_BLOCK,
  'link': LEVEL_LINK,
  'grid:inline': LEVEL_INLINE,
  'mention': LEVEL_INLINE,
};

const textContainers = [
  'p', 'p:hidden', 'quote', 'code',
  'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
  'ol', 'ul',
  'link',
];

const numToVulgar = {
  16: '1/6',
  20: '1/5',
  25: '1/4',
  33: '1/3',
  50: '1/2',
  66: '2/3',
  75: '3/4',
  100: '1/1',
};

function parseColSize (size) {
  if (!size) {
    return 1;
  }
  const bits = size.split('/');
  return bits[0] / bits[1];
}

export function levelOf (node) {
  return levels[node.name] || null;
}

export function isBlock (node) {
  return (levels[node.name] === LEVEL_BLOCK);
}

export function isInline (node) {
  return (levels[node.name] === LEVEL_INLINE);
}

export function isButton (node) {
  return [
    'button',
    'recalcbutton',
    'resetbutton',
    'goalseekbutton',
    'timer',
    'submitbutton',
    'submitbutton2',
    'actionbutton',
  ].includes(node.attr.type);
}

export function isMeta (node) {
  return node.name === 'meta';
}

function constrain (parent, assert, onError) {
  let i = 0;
  while (i < parent.children.length) {
    const child = parent.children[i];
    if (!assert(child)) {
      onError(parent, child, assert);
    }
    if (parent.children[i] === child) {
      // if the element has been replaced (by the error handler)
      // we need to re-evaluate it because handler may have
      // simply un-nested something which is itself a violation.
      // if nothing has changed, we're free to move to the next node:
      i++;
    }
  }
}

function validateAndFixColumns (node, body, options) {
  // If a col is encountered that would make the sum of col sizes in a row more
  // than 1, a new row must be created such that it follows the full one and the
  // col (and any subsequent col) moved to that instead.
  let total = 0;
  let overflow;
  for (let i = 0; i < node.children.length;) {
    const child = node.children[i];
    let size = parseColSize(child.attr.size);
    if (size > 1 || !isFinite(size)) {
      size = 1;
      child.attr.size = '1/1';
      options.report(size > 1 ? 'col size > 1' : 'col size invalid');
    }
    if (overflow || (total + size) > 1) {
      options.report('row overflow');
      if (!overflow) {
        const idx = body.children.indexOf(node);
        const nextSibling = (idx > -1) ? body.children[idx + 1] : null;
        overflow = new Element('row', Object.assign({}, node.attr));
        body.insertBefore(overflow, nextSibling);
      }
      node.removeChild(child);
      overflow.appendChild(child);
    }
    else {
      i++;
    }
    total += size;
  }
  // The client must [then] ensure that the sizes in both rows sum to 1. A simple
  // way to do this is to expand the largest col size to fill the remaining space.
  let biggest = null;
  let colSum = 0;
  for (let i = 0; i < node.children.length; i++) {
    const col = node.children[i];
    colSum += parseColSize(col.attr.size);
    if (!biggest || parseColSize(biggest.attr.size) <= parseColSize(col.attr.size)) {
      biggest = col;
    }
  }
  // because of 1/6 (which is 16.666666%) we need to create a rounded sum as the
  // total will otherwise end up as 0.9999999999999999
  const roundedSum = Math.round(colSum * 1000) / 1000;
  if (roundedSum < 1 && biggest) {
    const remaining = colSum - parseColSize(biggest.attr.size);
    const newSize = Math.floor((1 - remaining) * 100);
    // There are limits to how far we can take this fix. If someone manually
    // creates a 1/5 column we can't resolve the sizes. In case we can't, the
    // least destructive thing to do is to not touch the size here.
    if (numToVulgar[newSize]) {
      biggest.attr.size = numToVulgar[newSize];
    }
    if (!overflow) {
      options.report('row underflow');
    }
  }
}

function validateAndFixIDs (body, options) {
  const usedIDs = {};
  const needIDs = [];

  visit(body, node => {
    const id = node.id;
    if (!id || id in usedIDs || !id.match(/^[A-Za-z0-9]{10}$/)) {
      needIDs.push(node);
    }
    usedIDs[id] = (usedIDs[id] || 0) + 1;
  });

  needIDs.forEach(node => {
    if (!node.id) {
      options.report(`id missing from ${node.name}`);
    }
    else if (node.id) {
      options.report(`id collision: ${node.id}`);
    }
    do {
      node.id = getID();
    }
    while (usedIDs[node.id]);
    usedIDs[node.id] = 1;
  });
}

export function validateAndFix (node, options = {}, lineage = []) {
  if (!options.stats) {
    options.stats = {};
  }
  if (!options.report) {
    options.report = type => {
      options.stats[type] = (options.stats[type] || 0) + 1;
    };
  }

  if (node.attr) {
    for (const key of Object.keys(node.attr)) {
      // some attrs should be arrays, some should be objects, all others should be strings
      if (key === 'annotations' && !Array.isArray(node.attr[key])) {
        delete node.attr[key];
      }
      if (Array.isArray(node.attr[key])) {
        node.attr[key] = node.attr[key].filter(d => d && typeof d === 'object');
      }
    }
  }

  if (node.name === 'body') {
    // may contain any block element or row
    constrain(
      node,
      d => (d.name === 'row' && d.attr.layout) || isBlock(d) || isMeta(d),
      (parent, child) => {
        // rows that are not tagged layout are removed (keeping content)
        if (child.name === 'row') {
          // remove this and its column
          child.children.forEach(col => {
            col.children.forEach(block => {
              parent.insertBefore(block, child);
            });
          });
          parent.removeChild(child);
          options.report('redundant row');
        }
        // it can fit in a block, so wrap it with a paragraph
        else if (levelOf(child) < LEVEL_BLOCK) {
          const p = new Element('p');
          p.appendChild(child);
          parent.replaceChild(p, child);
          options.report(`body contains ${child.name}`);
        }
        // it's a body?
        else {
          parent.removeChild(child);
          options.report(`body contains ${child.name}`);
        }
      },
    );
    // body should contain at least 1 paragraph
    if (!node.children.length) {
      node.appendChild(new Element('p'));
    }
  }
  else if (node.name === 'row') {
    // may only contain cols
    constrain(
      node,
      d => d.name === 'col',
      (parent, child) => {
        // If an element is found in a row that is not a col, the correct resolution is:
        // Inject a full size (1/1) col into the row prior to the encountered element,
        // and proceed to append all consecutive non-col elements into it (see: col)
        // until either a col is encountered, or no more elements are found.
        const col = new Element('col', { size: '1/1' });
        parent.insertBefore(col, child);
        let offender;
        const index = parent.children.indexOf(child);
        do {
          offender = parent.children[index];
          if (offender && offender.name !== 'col') {
            col.appendChild(offender);
            parent.removeChild(offender);
          }
        }
        while (offender);
        options.report(`row contains ${child.name}`);
      },
    );
    // cols must sum to 1/1
    const parentNode = lineage[lineage.length - 1];
    validateAndFixColumns(node, parentNode, options);
  }
  else if (node.name === 'col') {
    // may contain any block
    constrain(
      node,
      d => isBlock(d),
      (parent, child) => {
        if (levelOf(child) < LEVEL_BLOCK) {
          const p = new Element('p');
          p.appendChild(child);
          parent.replaceChild(p, child);
        }
        else if (levelOf(child) === LEVEL_COL) {
          // move the contents into current column (to be evaluated)
          child.children.forEach(sub => parent.appendChild(sub));
          parent.removeChild(child);
        }
        else {
          parent.removeChild(child);
        }
        options.report(`col contains ${child.name}`);
      },
    );
  }
  else if (levelOf(node) === LEVEL_GROUP) {
    // we don't have groups... yet
  }
  else if (isBlock(node)) {
    // may contain any inline
    constrain(
      node,
      d => levelOf(d) < LEVEL_BLOCK,
      (parent, child) => {
        // Because the nested block can be a grid:block we can't merge
        // or move grand-children. The only recourse is to promote the
        // child to live directly after its parent.
        if (isBlock(child)) {
          const parentNode = lineage[lineage.length - 1];
          parentNode.insertAfter(child, parent);
          parent.removeChild(child);
        }
        else {
          // In this case, this is a col or row or body or some such.
          // So something very messed up has happened and we're just going
          // to discard the offender.
          parent.removeChild(child);
        }
        options.report(`${parent.name} contains ${child.name}`);
      },
    );
    if (!node.children.length && textContainers.includes(node.name)) {
      // body should contain at least 1 paragraph
      node.appendChild(new TextNode(''));
    }
  }

  // recurse to fix all children (should they exist)
  if (node.children) {
    const lin = lineage.concat([ node ]);
    for (let j = 0; j < node.children.length; j++) {
      validateAndFix(node.children[j], options, lin);
    }
  }

  // post recursion fixes
  if (node.name === 'body') {
    validateAndFixIDs(node, options);
  }

  return node;
}
