import { assignCellStylesIfNeeded, type WorkbookBody } from '@grid-is/apiary/lib/csf';

import { getRequestOptionsFor } from './document';
import { paths, schemas } from './generatedTypes';
import { frontApiUrl, getJSON, gridfetch, postJSON, request, unJSON } from './request';

export type WorkbookInfo = schemas['WorkbookInfo'];

type NativeWorkbookRequest = paths['/native-workbook']['post']['requestBody']['content']['application/json'];
type NativeWorkbookResponse = paths['/native-workbook']['post']['responses']['200']['content']['application/json'];

/**
 * Creates a new native workbook with the given filename.
 */
export function nativeWorkbook (filename: string, body: Record<string, any>) {
  const payload = {
    filename,
    body,
  };
  return postJSON<NativeWorkbookResponse, NativeWorkbookRequest>('/native-workbook', payload);
}

type UploadResponse = paths['/source/{workbook_id}']['post']['responses']['200']['content']['application/json'];
type UploadHeaders = paths['/source/{workbook_id}']['post']['parameters']['header'];

/**
 * Uploads the given workbook with the given filename, optionally with an existing workbookId.
 */
export function upload (filename: string, body: File, workbookId: string | null) {
  const url = workbookId ? '/source/' + workbookId : '/source';
  const headers: UploadHeaders = {
    'X-Uploaded-Filename': encodeURIComponent(filename.normalize()),
  };
  return postJSON<UploadResponse, File>(url, body, { headers }).catch(err => {
    if (err.error_code == null) {
      err.error_code = 'upload_failed';
    }
    throw err;
  });
}

type UpdateNativeWorkbookRequest = paths['/document/{document_id}/native-workbook/{workbook_id}']['post']['requestBody']['content']['application/json'];
type UpdateNativeWorkbookResponse = paths['/document/{document_id}/native-workbook/{workbook_id}']['post']['responses']['200']['content']['application/json'];

export function updateNativeWorkbook (
  documentId: string,
  workbookId: string,
  body: any,
  version?: number,
  filename?: string,
) {
  const payload: UpdateNativeWorkbookRequest = {
    version,
    body: {
      schema_version: '4.0',
      ...body,
    },
  };
  if (filename) {
    payload.filename = filename;
  }
  return postJSON<UpdateNativeWorkbookResponse, UpdateNativeWorkbookRequest>(`/document/${documentId}/native-workbook/${workbookId}`, payload);
}

function getCompleteWorkbookBody (documentId: string, workbookId: string, opts: Record<string, any>, isEmbedded: boolean) {
  const url = `/document/${documentId}/workbook/${workbookId}/body${isEmbedded ? '?is_embedded=true' : ''}`;
  return getJSON<WorkbookBody>(url, opts);
}

function getPrunedWorkbookBody (documentId: string, workbookId: string, opts: Record<string, any>, isEmbedded: boolean, documentTag: string): Promise<WorkbookBody> {
  // Adding the .js file extension to the URL makes cloudflare consider it for caching
  // See https://developers.cloudflare.com/cache/about/default-cache-behavior#default-cached-file-extensions
  const url = `${frontApiUrl()}/workbook/transmogrify-v1/${documentId}/${documentTag}/${workbookId}/pruned-2.js${isEmbedded ? '?is_embedded=true' : ''}`;
  return gridfetch(url, opts, false).then(res => {
    if (res.status === 410) {
      // XXX: We fall back to loading the entire workbook. However, 410 indicates that the document is stale and should be reloaded.
      return getCompleteWorkbookBody(documentId, workbookId, opts, isEmbedded);
    }
    return unJSON(res);
  });
}

export function getWorkbook (
  documentId: string,
  workbookId: string,
  authToken: string,
  pruned: boolean,
  documentTag: string | null | undefined,
  isEmbedded: boolean,
) {
  const opts = getRequestOptionsFor(documentId, {}, authToken);
  const bodyLoadingPromise = pruned && documentTag
    ? getPrunedWorkbookBody(documentId, workbookId, opts, isEmbedded, documentTag)
    : getCompleteWorkbookBody(documentId, workbookId, opts, isEmbedded);
  return bodyLoadingPromise.then(wbBody => {
    assignCellStylesIfNeeded(wbBody);
    return wbBody;
  });
}

type GetWorkbookDocumentsResponse = paths['/workbook/{workbook_id}/documents']['get']['responses']['200']['content']['application/json'];

export function getWorkbookDocuments (id: string) {
  return getJSON<GetWorkbookDocumentsResponse>('/workbook/' + id + '/documents');
}

type WorkbookFromSampleUrlRequest = paths['/workbook/from-sample-url']['post']['requestBody']['content']['application/json'];
type WorkbookFromSampleUrlResponse = paths['/workbook/from-sample-url']['post']['responses']['200']['content']['application/json'];

export function workbookFromSampleURL (url: string, name: string) {
  const payload = {
    name,
    url,
  };
  return postJSON<WorkbookFromSampleUrlResponse, WorkbookFromSampleUrlRequest>('/workbook/from-sample-url', payload);
}

export function refresh (id: string) {
  return postJSON<{}>('/source/' + id + '/refresh');
}

type HistoryResponse = paths['/sources/{workbook_id}/history']['get']['responses']['200']['content']['application/json'];

export type WorkbookUpdate = schemas['WorkbookUpdate'];

export function history (id: string) {
  return getJSON<HistoryResponse>('/sources/' + id + '/history')
    .then(entries => {
      return entries.map(entry => {
        // fixes a problem with inconsistent prop typing
        const origin = entry.details && entry.details.origin;
        if (typeof origin === 'object') {
          entry.details.origin = 'remote';
          for (const key in origin) {
            Object.assign(entry.details, origin[key]);
          }
        }
        return entry;
      });
    });
}

type ListRemotesResponse = paths['/remotes']['get']['responses']['200']['content']['application/json'];

export type CloudDrive = schemas['CloudDrive'];

export function listRemotes () {
  return getJSON<ListRemotesResponse>('/remotes');
}

type ListCloudDriveDocsResponse = paths['/cloud_drive/{cloud_drive_id}/documents']['get']['responses']['200']['content']['application/json'];

export function listCloudDriveDocuments (cloudDriveId: string) {
  return getJSON<ListCloudDriveDocsResponse>(`/cloud_drive/${cloudDriveId}/documents`);
}

type ListRemoteFilesResponse = paths['/remote/{cloud_drive_id}/{folder_id}']['get']['responses']['200']['content']['application/json'];

export type CloudFile = schemas['CloudFile'];

export function listRemoteFiles (remoteId: string, folderId?: string) {
  let url = '/remote/' + remoteId;
  if (folderId) {
    url += '/' + folderId;
  }
  return getJSON<ListRemoteFilesResponse>(url);
}

type LinkRemoteFileRequest = paths['/remotes']['post']['requestBody']['content']['application/json'];
type LinkRemoteFileResponse = paths['/remotes']['post']['responses']['200']['content']['application/json'];

export function linkRemoteFile (remoteId: string, remoteFileId: string) {
  const payload = {
    remote_file_id: remoteFileId,
    remote_id: remoteId,
  };
  return postJSON<LinkRemoteFileResponse, LinkRemoteFileRequest>('/remotes', payload);
}

type RegisterRemoteRequest = paths['/remote']['post']['requestBody']['content']['application/json'];
type RegisterRemoteResponse = paths['/remote']['post']['responses']['200']['content']['application/json'];
export type Provider = RegisterRemoteRequest['provider'];

export function registerRemote (code: string, provider: Provider, redirect_uri: string, code_challenge?: string) {
  const payload = {
    code: code,
    provider: provider,
    redirect_uri: redirect_uri,
    code_challenge: code_challenge,
  };
  return postJSON<RegisterRemoteResponse, RegisterRemoteRequest>('/remote', payload);
}

type EditRemoteRequest = paths['/remote/{cloud_drive_id}']['post']['requestBody']['content']['application/json'];

export function editRemoteLabel (remoteId: string, label: string) {
  return postJSON<{}, EditRemoteRequest>('/remote/' + remoteId, { label });
}

export function removeCloudDrive (cloudDriveId: string): Promise<{}> {
  return request(`/cloud_drive/${cloudDriveId}`, { method: 'DELETE' });
  // XXX: surely, the listRemotes cache should be cleared here
}

type ImportFromUrlRequest = paths['/workbook/from-url']['post']['requestBody']['content']['application/json'];
type ImportFromUrlResponse = paths['/workbook/from-url']['post']['responses']['200']['content']['application/json'];

export function importFromURL (url: string, filename: string) {
  const payload = {
    url,
    filename,
  };
  return postJSON<ImportFromUrlResponse, ImportFromUrlRequest>('/workbook/from-url', payload);
}

export type SyncSchedule = schemas['SyncSchedule'];
type SyncScheduleUpdateRequest = paths['/workbook/{workbook_id}/sync-schedule']['post']['requestBody']['content']['application/json'];

export function getSyncScheduleForWorkbook (workbookId: string) {
  return getJSON<SyncSchedule>(`/workbook/${workbookId}/sync-schedule`);
}

function toISOTime (hours: number, minutes: number) {
  const d = new Date(0);
  d.setHours(hours);
  d.setMinutes(minutes);
  const [ , time ] = d.toISOString().split('T');
  return time;
}

export function updateSyncScheduleForWorkbook (workbookId: string, refreshOffsetHours: number|undefined, refreshOffsetMinutes: number|undefined, refreshIntervalSeconds: number|undefined) {
  const schedule: SyncScheduleUpdateRequest = {};
  if (refreshOffsetHours !== undefined && refreshOffsetMinutes !== undefined) {
    schedule.refresh_offset = toISOTime(refreshOffsetHours, refreshOffsetMinutes);
  }
  if (refreshIntervalSeconds !== undefined) {
    schedule.refresh_interval_seconds = refreshIntervalSeconds;
  }
  return postJSON<SyncSchedule, SyncScheduleUpdateRequest>(`/workbook/${workbookId}/sync-schedule`, schedule);
}

export function getFormSubmissionsWorkbook (documentId: string) {
  type Response = paths['/workbook/form-submissions']['post']['responses']['200']['content']['application/json'];
  type Request = paths['/workbook/form-submissions']['post']['requestBody']['content']['application/json'];
  return postJSON<Response, Request>('/workbook/form-submissions', { document_id: documentId });
}
