import { getFormSubmissionsWorkbook, importFromURL, linkRemoteFile, nativeWorkbook, upload, WorkbookInfo } from '@/api/source';
import { Doc } from '@/grid/Doc';
import type { FileToImport, NativeWorkbook, UploadedFile } from '@/components/Document/AddWorkbook/AddWorkbook';
import { MAX_SOURCE_SIZE } from '@/constants';

import { removeFileExtension } from './file';

class WorkbookProcessingError extends Error {
  error_code: string;
  constructor (message: string, code: 'unrecognized_format' | 'too_big' | 'duplicate_names') {
    super(message);
    this.error_code = code;
  }
}

function assertValidFile (file: FileToImport) {
  if (file.type === 'native') {
    return;
  }
  if (!/\.(xls[xm]|csv)$/i.test(file?.name)) {
    throw new WorkbookProcessingError('Unsupported file format', 'unrecognized_format');
  }
  if (file.type === 'upload' && file.file.size > MAX_SOURCE_SIZE) {
    throw new WorkbookProcessingError('File size limit exceeded', 'too_big');
  }
}

export function upsertWorkbook (file: NativeWorkbook | UploadedFile, workbookId: string | null = null) {
  if (file.type === 'native') {
    return nativeWorkbook(file.name, {
      schema_version: '4.0',
      sheets: [ {
        name: 'Sheet1',
        cells: {},
      } ],
    });
  }
  else {
    return upload(file.name, file.file, workbookId);
  }
}

export async function processWorkbook (doc: Doc, source: FileToImport, idToReplace: string | null) {
  // determine if doc is a Doc instance
  if (!(doc instanceof Doc)) {
    throw new Error('source must be a Doc object');
  }
  const isRemote = source.type === 'remote-file';
  const isURL = source.type === 'url';

  const workbooks = doc.model.getWorkbooks();
  const workbookToReplace = workbooks.find(wb => wb.id === idToReplace);

  // do not allow two workbooks with the same name (unless we are replacing with same name)
  const newName = removeFileExtension(source.name); // XXX: API should not send .gsheet in the first place
  const haveNameCollision = workbooks.some(wb => {
    return wb.id !== idToReplace && newName.toLowerCase().normalize() === removeFileExtension(wb.name.toLowerCase().normalize());
  });
  if (haveNameCollision) {
    const replacingMsg = idToReplace ? `, replacing workbook ID ${idToReplace},` : '';
    const conflictingId = workbooks.find(wb => removeFileExtension(wb.name) === newName)?.id;
    throw new WorkbookProcessingError(
      `New workbook ${source.name}${replacingMsg}` +
      `, clashes with workbook already in model with ID ${conflictingId}`,
      'duplicate_names',
    );
  }

  let ret: { workbook: WorkbookInfo };
  if (workbookToReplace && (source.type === 'upload' || source.type === 'native') && !workbookToReplace.cloud_connection) {
    // replace local/local
    assertValidFile(source);
    try {
      ret = await upsertWorkbook(source, workbookToReplace.id);
    }
    catch (e: any) {
      if (e && e.error_code === 'workbook_has_incompatible_origin') {
        // most likely a cloud connected workbook which has lost its connection; upload this as new workbook instead
        ret = await upsertWorkbook(source);
      }
      else {
        throw e;
      }
    }
  }
  else if (isRemote) {
    // replace local/remote || replace remote/remote
    ret = await linkRemoteFile(source.remoteId, source.remoteFileId);
  }
  else if (isURL) {
    ret = await importFromURL(source.url, source.name);
  }
  else if (source.type === 'form-submissions') {
    const workbook = await getFormSubmissionsWorkbook(source.documentId);
    ret = { workbook };
  }
  else {
    // simple upload || replace remote/local
    assertValidFile(source);
    ret = await upsertWorkbook(source);
  }

  await doc.attachSource(ret.workbook, workbookToReplace?.id);
  return ret;
}
