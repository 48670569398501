const DEFAULT_MESSAGE = 'Invalid option value';

/**
 * Class representing an option validation error from validation of properties.
 *
 * Severity is indicated by its `.level` property:
 * OptionError.WARNING => 1 => Valid but likely not intended.
 * OptionError.ERROR => 2 => Invalid property value.
 *
 * @augments Error
 */
export class OptionError extends Error {
  /** @type {0} */
  static OKAY = 0;
  /** @type {1} */
  static WARNING = 1;
  /** @type {2} */
  static ERROR = 2;

  /**
   * Create an OptionError.
   * @param {string} [message] - The error message.
   * @param {number} [level=2] - The severity of the error (0 = Okay, 1 = warning, 2 = error).
   */
  constructor (message = DEFAULT_MESSAGE, level = 2) {
    super(message);
    /** @type {Readonly<(0 | 1 | 2)>} */
    this.level = (level === 0 || level === 1 || level === 2) ? level : 2;
    Object.freeze(this);
  }
}
