/**
 * Unescape an Excel style double quoted string. Removes surrounding quotes and
 * collapses escaped quotes with unescaped ones. If the string was not quoted
 * it is returned as is.
 *
 * @param {string} string A possibly quoted string
 * @return {string} The unquoted string
 */
export function unesc (string) {
  if (string.length > 1 && string[0] === '"' && string[string.length - 1] === '"') {
    return string.slice(1, -1).replace(/""/g, '"');
  }
  return string;
}
