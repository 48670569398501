import React from 'react';

import { DEFAULT_BODY_FONT, DEFAULT_TITLE_FONT, fonts } from '@/constants/fonts';
import { languages } from '@/constants/languages';

import { customChangeColors, customChartColors, gridPal2 } from './chart/utils/color';
import { MAX_BUTTON_FONT_SIZE, MAX_FONT_SIZE, MIN_BUTTON_FONT_SIZE, MIN_FONT_SIZE, MIN_TIMER_INTERVAL } from './constants';
import { BoolDataOption } from './options/handlers/BoolDataOption';
import { BoolOption } from './options/handlers/BoolOption';
import { ColorOption } from './options/handlers/ColorOption';
import { DataOption } from './options/handlers/DataOption';
import { EnumOption } from './options/handlers/EnumOption';
import { FormatOption } from './options/handlers/FormatOption';
import { NumberOption } from './options/handlers/NumberOption';
import { OptionList } from './options/handlers/OptionList';
import { PaletteOption } from './options/handlers/PaletteOption';
import { ReferenceOption } from './options/handlers/ReferenceOption';
import { StringOption } from './options/handlers/StringOption';
import { TargetOption } from './options/handlers/TargetOption';
import { UrlOption } from './options/handlers/UrlOption';
import { ValueOption } from './options/handlers/ValueOption';

const linkOpts = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

const formatHelpUrl = 'https://support.office.com/en-us/article/number-format-codes-5026bbd6-04bc-48cd-bf33-80f18b4eae68';
const latexHelpUrl = 'https://grid.is/@grid/latex-math-equation-4KH5jcVIQe:kt1OUW4eiVg';
const stepHelpUrl = 'https://grid.is/@grid/min-max-and-step-koLo9H4dR2Srv:a98UFKhQ';

const formatOptions = [
  { label: 'Default', description: '(Same as cell)', value: '' }, // the default option
  { label: 'Integer', description: '1234', value: '0' },  // integer
  { label: 'Number', description: '1,234.5', value: '#,##0.0' }, // formatted number
  { label: 'Currency in US dollars', description: '$1,234', value: '"$"#,##0' }, // dollars
  { label: 'Currency in euros', description: '€1,234', value: '"€"#,##0' }, // euros
  { label: 'Currency in pounds sterling', description: '£1,234', value: '"£"#,##0' }, // pounds
  { label: 'Short date', description: '01/28/2008', value: 'mm/dd/yyyy' }, // short date
  { label: 'Long date', description: 'Monday, March 09, 1987', value: 'dddd, mmmm dd, yyyy' }, // long date
  { label: 'Time', description: '9:34:12 AM', value: 'h:mm:ss AM/PM' }, // time
  { label: 'Percentage', description: '12.3%', value: '0.0%' }, // percent
  { label: 'Fraction', description: '12 3/8', value: '#" "?/?' }, // fractions
  { label: 'Scientific', description: '1.2E+03', value: '0.0E+00' }, // sci
  { label: 'Text', description: '1234.56', value: '@' }, // text
];
export const axisTitle = new StringOption({
  name: 'title',
  label: 'Title',
  tooltip: 'Title of the axis.',
});
export const axisReverse = new BoolOption({
  name: 'reverse',
  label: 'Reverse',
  defaultValue: 'false',
  tooltip: 'Whether to reverse the order of axis values.',
});
export const axisFormat = new FormatOption({
  name: 'format',
  label: 'Number format',
  options: formatOptions,
  tooltip: 'The number format code by which the axis values are formatted.',
  help: <>See <a href={formatHelpUrl} {...linkOpts}>Excel documentation</a></>,
});
export const axisMax = new NumberOption({
  name: 'max',
  label: 'Maximum',
  defaultValue: 'auto',
  tooltip: 'Maximum value for the axis.',
});
export const axisMin = new NumberOption({
  name: 'min',
  label: 'Minimum',
  defaultValue: 'auto',
  tooltip: 'Minimum value for the axis.',
});
export const axisMinBubble = new NumberOption({
  name: 'min',
  label: 'Minimum',
  defaultValue: '0',
  tooltip: 'Minimum value for the axis.',
});
export const axisClip = new BoolOption({
  name: 'clip',
  label: 'Truncate overflowing data',
  tooltip: 'Makes the chart truncate any graphics outside min or max boundaries.',
});
export const axisType = new EnumOption({
  name: 'type',
  label: 'Scale',
  defaultValue: 'linear',
  options: [
    { value: 'linear', label: 'Linear' },
    { value: 'log', alias: 'logarithmic', label: 'Log' },
  ],
  tooltip: 'How to display the axis and treat the data.',
});
export const axisDisabled = new BoolOption({
  name: 'disabled',
  label: 'Disabled',
  defaultValue: 'false',
  tooltip: 'Is the secondary axis in use by the chart.',
});
export const data = new DataOption({
  name: 'expr',
  label: 'Data',
  tooltip: 'The data this element should render.',
  help: 'Select data in the spreadsheet, e.g. =A2:B5',
});
export const expSheet = new ReferenceOption({
  name: 'expr',
  label: 'Data',
  tooltip: 'The data this element should render.',
});
export const optHighlightRef = new ReferenceOption({
  name: 'highlight',
  label: 'Highlight',
  tooltip: 'A range to highlight',
});
export const optHighlightColor = new ColorOption({
  name: 'highlightColor',
  label: 'Highlight color',
  tooltip: 'Highlight output color',
  placeholder: 'Type color code or select hue',
});
export const expKpi = new DataOption({
  name: 'expr',
  label: 'Value',
  tooltip: 'The highlighted value of the element.',
});
export const exprPrev = new DataOption({
  name: 'exprPrev',
  label: 'Comparison value',
  tooltip: 'The value to compare to the highlighted value.',
});
export const changeCalcType = new EnumOption({
  name: 'changeCalcType',
  label: 'Show change between values as',
  defaultValue: 'delta',
  options: [
    // We reserve the blank here in case we would like to add auto-magic to this
    // { value: '', label: 'Automatic' },
    { value: 'delta', label: 'Absolute difference' },
    { value: 'percent', label: 'Percentage difference' },
    { value: 'none', label: 'Target value unchanged' },
  ],
  tooltip: 'How the difference between actual and target values is displayed.',
});
export const showMovementIcon = new BoolOption({
  name: 'showMovementIcon',
  label: 'Show change icon',
  defaultValue: 'true',
  tooltip: 'Include an icon that signifies an increase, decrease, or no change between the actual and target values.',
});
export const exprWorkbook = new ReferenceOption({
  name: 'expr',
  label: 'Data source reference',
  tooltip: 'The source this element refers to.',
  help: 'A reference to any cell in the data source you\'re linking to. Blank = first source.',
});
export const exprColumns = new DataOption({
  name: 'expr',
  label: 'Column data',
  tooltip: 'The data this element should render as columns.',
  help: 'Select data in the spreadsheet, e.g. =A2:B5',
});
export const exprLines = new DataOption({
  name: 'exprLines',
  label: 'Line data',
  tooltip: 'The data this element should render as lines.',
  help: 'Select data in the spreadsheet, e.g. =A2:B5',
});
export const chartColors = new PaletteOption({
  name: 'chartColors',
  label: 'Color palette',
  tooltip: 'A list of colors to be used for the display element. Uses HTML color codes.',
  options: customChartColors,
});
export const changeColors = new PaletteOption({
  name: 'changeColors',
  label: 'Color palette',
  tooltip: 'A list of colors to be used for the display element. Uses HTML color codes.',
  options: customChangeColors,
});
export const targetCell = new TargetOption({
  name: 'expr',
  label: 'Target cell',
  tooltip: 'The cell in the spreadsheet the value should be written to.',
  help: 'Select data in the spreadsheet, e.g. =A2',
});
export const equation = new StringOption({
  name: 'equation',
  label: 'LaTeX code',
  tooltip: 'LaTeX notation for the math equation to display',
  help: <>See <a href={latexHelpUrl} {...linkOpts}>documentation</a> for details</>,
});
export const sourceUrl = new UrlOption({ // url should take over from expr for images and embeds
  name: 'url',
  label: 'Source URL',
  tooltip: 'A link to the asset being displayed',
});
export const linkUrl = new UrlOption({
  name: 'href',
  label: 'Link URL',
  tooltip: 'You can set a link to open when people click the element. (for example, https://grid.is)',
});
export const writeCell = new ReferenceOption({
  name: 'writeCell',
  label: 'Cell to change',
  tooltip: 'The cell whose value will be changed in order to affect the goal cell.',
});
export const readCell = new ReferenceOption({
  name: 'readCell',
  label: 'Goal cell',
  tooltip: 'The formula cell in which a goal value is desired.',
});
export const targetValue = new NumberOption({
  name: 'targetValue',
  label: 'Goal value',
  defaultValue: '0',
  tooltip: 'The result value desired in the goal cell.',
});
export const elementVisibility = new BoolOption({
  name: 'visible',
  label: 'Visibility',
  defaultValue: 'true',
  tooltip: 'Is the element shown or hidden?',
});
export const optVisibilityNoPrint = new BoolOption({
  name: 'visible',
  label: 'Visibility',
  defaultValue: '=NOT(GRID.ISPRINT())',
  tooltip: 'Is the element shown or hidden?',
});
export const disabled = new BoolOption({
  name: 'disabled',
  label: 'Disable user input',
  defaultValue: 'false',
  tooltip: 'Prevents changes to the element\'s value by disabling user input.',
});
export const disableHelper = new BoolOption({
  name: 'disableHelper',
  label: 'Disable input helpers',
  defaultValue: 'false',
  tooltip: 'Whether to display additional assistive buttons (such as steppers).',
});
export const autoplay = new BoolOption({
  name: 'autoplay',
  label: 'Autoplay',
  defaultValue: 'false',
  tooltip: 'Should the element automatically start?',
});
export const colorByPoint = new BoolOption({
  name: 'colorByPoint',
  label: 'Color by point',
  defaultValue: 'false',
  tooltip: 'Vary colors by point rather than series.',
});
export const loop = new BoolOption({
  name: 'loop',
  label: 'Loop',
  defaultValue: 'true',
  tooltip: 'Should the value be set back to a minimum when it reaches maximum?',
});
export const elementTitle = new StringOption({
  name: 'title',
  label: 'Chart title',
  tooltip: 'A title displayed as a heading at the top of the element.',
});
export const elementSubtitle = new StringOption({
  name: 'subtitle',
  label: 'Chart subtitle',
  tooltip: "A sub-heading displayed below the element's title.",
});
export const elementFootnote = new StringOption({
  name: 'footnote',
  label: 'Footnote',
  tooltip: 'Additional information displayed underneath the chart.',
});
export const elementFootnoteLink = new UrlOption({
  name: 'footnoteLink',
  label: 'Footnote link',
  tooltip: 'Open a Web link when people click the footnote.',
});
export const tableTitle = new StringOption({
  name: 'title',
  label: 'Title',
  tooltip: 'A title displayed as a heading at the top of the table.',
});
export const tableSubtitle = new StringOption({
  name: 'subtitle',
  label: 'Subtitle',
  tooltip: 'A subtitle displayed below the heading at the top of the table.',
});
export const tableDensity = new StringOption({
  name: 'tableDensity',
  label: 'Table density',
  options: [
    { value: '', label: 'Default' },
    { value: 'comfortable', label: 'Comfortable' },
    { value: 'compact', label: 'Compact' },
  ],
  tooltip: 'How the difference between actual and target values is displayed.',
});
export const tableLayout = new EnumOption({
  name: 'tableLayout',
  label: 'Column widths',
  options: [
    { value: '', alias: 'auto', label: 'Auto' },
    { value: 'sheet', label: 'Based on sheet columns' },
    { value: 'even', label: 'Evenly distributed' },
  ],
  tooltip: 'How the column widths are determined.',
});
export const titleLabel = new StringOption({
  name: 'title',
  label: 'Label',
  tooltip: 'A label for your element.',
});
export const tooltip = new StringOption({
  name: 'tooltip',
  label: 'Tooltip text',
  tooltip: 'The text that is visible on hover.',
});
export const tooltipLabel = new StringOption({
  name: 'title',
  label: 'Label text',
  tooltip: 'Number, letter or special character.',
});
export const tooltipFill = new BoolOption({
  name: 'emphasize',
  label: 'Use accent color',
  defaultValue: 'false',
  tooltip: 'Fill label background. Change fill by altering accent color in Document appearance.',
});
export const optInlineLegacy = new BoolOption({
  name: 'inline',
  label: 'Flow with text',
  defaultValue: 'false',
  tooltip: 'Should the element be displayed as a single block or should it flow with text.',
});
export const optInlineType = new EnumOption({
  name: 'inline',
  label: 'Arrange label and value',
  defaultValue: 'col',
  options: [
    { value: 'col', alias: 'false', label: 'Horizontally as a column' },
    { value: 'row', label: 'Vertically as a row' },
    { value: 'text', alias: 'true', label: 'Flow with text' },
  ],
  tooltip: 'Controls how the label and value are aligned within the element.',
});
export const optInlineTypeText = new EnumOption({
  ...optInlineType,
  defaultValue: 'text',
});
export const striped = new BoolOption({
  name: 'striped',
  label: 'Alternate row colors',
  defaultValue: 'true',
  tooltip: 'Apply shading to alternate rows.',
});
export const xAxisLabels = new DataOption({
  name: 'labels',
  label: 'X-axis labels',
  tooltip: 'The axis labels for the data, e.g. date labels for time series.',
});
export const yAxisLabels = new DataOption({
  name: 'labels',
  label: 'Y-axis labels',
  tooltip: 'The axis labels for the data, e.g. date labels for time series.',
});
export const scatterLabels = new DataOption({
  name: 'labels',
  label: 'Hover labels',
  tooltip: 'The labels for the data, shown when hovering the plotted marks.',
});
export const rowHeaders = new DataOption({
  name: 'labels',
  label: 'Row headers',
  tooltip: 'Row headers are displayed in front of the table’s columns, clearly labeling each row. Many tables don’t have row headers.',
});
export const optionLabels = new DataOption({
  name: 'labels',
  label: 'Option labels',
  tooltip: <>Sets alternative labels for the <b>Options</b>.</>,
});
export const optHeaderTitle = new StringOption({
  name: 'headerTitle',
  label: 'Header title',
  tooltip: 'The title of the column or/and row headers',
});
export const legend = new DataOption({
  name: 'legend',
  label: 'Legend',
  tooltip: 'The names of the data series that make up the chart.',
});
// Scatter plot uses the legend option to label its axes. The legend itself comes from the chart's
// categories, but we keep the option for compatibility with other chart types.
export const legendScatter = new DataOption({
  name: 'legend',
  label: 'Axis titles',
  tooltip: 'Add titles for your x-axis, y-axis, and size-axis.',
});
export const legendCols = new DataOption({
  name: 'legend',
  label: 'Column legend',
  tooltip: 'The names of the data series that make up the chart.',
});
export const legendLines = new DataOption({
  name: 'legendLines',
  label: 'Line legend',
  tooltip: 'The names of the data series that make up the chart.',
});
export const columnHeaders = new DataOption({
  name: 'legend',
  label: 'Column headers',
  tooltip: 'Column headers are displayed at the top of the table, clearly labeling each column. Good column headers can make tables more legible.',
});
export const optFooter = new DataOption({
  name: 'summary',
  label: 'Footer',
  tooltip: 'Table footer is displayed at the bottom of the table. A great way to summarize the values above',
});
export const optFooterTitle = new StringOption({
  name: 'summaryTitle',
  label: 'Footer title',
  tooltip: 'A title for the footer',
});
export const legendVisible = new BoolOption({
  name: 'legendVisible',
  label: 'Legend visible',
  options: [
    { value: '', label: 'Auto' },
    { value: 'true', label: 'Always' },
    { value: 'false', label: 'Never' },
  ],
  tooltip: 'Is the legend for this element shown or hidden?',
});
export const sortBy = new NumberOption({
  name: 'sortBy',
  label: 'Sort by',
  defaultValue: '1',
  tooltip: 'The number of which series the data should be sorted by (e.g. 1 for the first series).',
});
export const sortOrder = new EnumOption({
  name: 'sortOrder',
  label: 'Sort order',
  options: [
    { value: '', alias: 'none', label: 'None' },
    { value: 'descending', alias: 'desc', label: 'Descending' },
    { value: 'ascending', alias: 'asc', label: 'Ascending' },
  ],
  tooltip: <>The default sorting order when <b>Sort by</b> is set.</>,
});
export const sortOrderPie = new EnumOption({
  name: 'sortOrder',
  label: 'Sort order',
  options: [
    { value: '', alias: 'none', label: 'None' },
    { value: 'descending', alias: 'desc', label: 'Descending' },
    { value: 'ascending', alias: 'asc', label: 'Ascending' },
    // disabled in the UI until we investigate this
    // { value: 'helium', label: 'Larger segments on top' },
  ],
  tooltip: <>The order in which values appear in the chart.</>,
});
export const seriesOrientation = new EnumOption({
  name: 'dir',
  label: 'Series orientation',
  options: [
    { value: '', alias: 'auto', label: 'Auto' },
    { value: 'col', label: 'Columns' },
    { value: 'row', label: 'Rows' },
  ],
  tooltip: 'Whether data series are read horizontally (row) or vertically (col) from a referenced data range.',
});
export const stacked = new BoolOption({
  name: 'stacked',
  label: 'Stacked',
  defaultValue: 'false',
  tooltip: 'Whether to display series stacked or grouped.',
});
export const interpolate = new EnumOption({
  name: 'interpolate',
  label: 'Interpolation',
  defaultValue: 'linear',
  options: [
    { value: 'linear', label: 'Linear' },
    { value: 'step', label: 'Step' },
    { value: 'step-after', label: 'Step after' },
    { value: 'step-before', label: 'Step before' },
    { value: 'monotone', label: 'Monotone' },
    { value: 'basis', label: 'Basis' },
  ],
  tooltip: 'How a line is interpolated between values.',
});
export const blanks = new EnumOption({
  name: 'blanks',
  label: 'Blank value handling',
  defaultValue: 'gap',
  options: [
    { value: 'gap', label: 'Gap' },
    { value: 'zero', label: 'Zero' },
    { value: 'span', label: 'Span' },
  ],
  tooltip: 'How breaks or gaps in the series are handled.',
});
export const optShowDots = new EnumOption({
  name: 'lineDots',
  label: 'Show dots on lines',
  options: [
    { value: '', label: 'When there are few values' },
    { value: 'false', label: 'Never' },
  ],
  tooltip: 'Should dots be placed on lines to indicate data points?',
});
export const width = new EnumOption({
  name: 'width',
  label: 'Width',
  defaultValue: 'medium',
  options: [
    { value: 'x-small', label: 'X-small' },
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' },
    { value: 'x-large', label: 'X-large' },
    { value: 'full', label: 'Full' },
  ],
  tooltip: 'The width of the element.',
});
export const optWidthAuto = new EnumOption({
  name: 'width',
  label: 'Width',
  defaultValue: '',
  options: [
    { value: '', label: 'Auto' },
    { value: 'x-small', label: 'X-small' },
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' },
    { value: 'x-large', label: 'X-large' },
    { value: 'full', label: 'Full' },
  ],
  tooltip: 'The width of the element.',
});
export const imageWidth = new EnumOption({
  name: 'width',
  label: 'Size',
  options: [
    { value: '', alias: 'auto', label: 'Auto' },
    { value: 'xx-small', label: 'XX-small' },
    { value: 'x-small', label: 'X-small' },
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' },
    { value: 'x-large', label: 'X-large' },
    { value: 'full', label: 'Full' },
  ],
  tooltip: 'The width of the element.',
});

export const optSheetWidth = new NumberOption({
  name: 'width',
  label: 'Width',
  defaultValue: '',
});
export const optSheetHeight = new NumberOption({
  name: 'height',
  label: 'Height',
  defaultValue: '',
});

export const optInlineSize = new StringOption({
  name: 'size',
  label: 'Size',
  options: [
    { value: '', alias: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' },
  ],
});
export const framing = new EnumOption({
  name: 'framing',
  label: 'Scale to area',
  defaultValue: 'contain',
  options: [
    { value: 'cover', label: 'Cover' },
    { value: 'contain', label: 'Contain' },
  ],
  tooltip: 'How the image is scaled to fit its designated size. Has no effect on "full" or "auto" sizes, nor square or circular images.',
});
export const imageShape = new EnumOption({
  name: 'shape',
  label: 'Shape',
  defaultValue: 'rect',
  options: [
    { value: 'rect', label: 'Rectangle' },
    { value: 'rrect', label: 'Rectangle with rounded corners' },
    { value: 'circle', label: 'Circle' },
    { value: 'ellipse', label: 'Ellipse' },
  ],
  tooltip: 'The shape of the image. Any parts of the image outside the shape will be hidden.',
});
export const value = new ValueOption({
  name: 'value',
  label: 'Value',
  defaultValue: 'true',
  tooltip: <>The value written to the <b>Target cell</b> when the element is activated.</>,
});
export const timerValue = new ValueOption({
  name: 'value',
  label: 'Value',
  defaultValue: 'auto',
  tooltip: <>The value written to the <b>Target cell</b> when the timer updates.</>,
});
export const valueOff = new ValueOption({
  name: 'valueOff',
  label: 'Value off',
  defaultValue: 'false',
  tooltip: <>The value written to the <b>Target cell</b> when the element is deactivated.</>,
});
export const optValueVisible = new BoolOption({
  name: 'valueVisible',
  label: 'Show value',
  options: [
    { value: '', alias: 'auto', label: 'Auto' },
    { value: 'true', label: 'Show' },
    { value: 'false', label: 'Hide' },
  ],
  tooltip: 'Whether or not to show the value on the slider.',
});
export const disableTooltip = new BoolOption({
  name: 'disableTooltip',
  label: 'Disable tooltip',
  defaultValue: 'false',
  tooltip: 'Whether or not to show a value tooltip when using the slider.',
});
export const valueLabels = Object.assign(new EnumOption({
  name: 'valueLabels',
  label: 'Show data labels',
  defaultValue: 'none',
  options: [
    { value: 'none', label: 'None' },
    { value: 'selective', label: 'When they fit' },
    { value: 'all', label: 'All' },
  ],
  tooltip: 'Whether or not to show value labels on the chart.',
}), {
  NONE: 'none',
  SOME: 'selective',
  ALL: 'all',
});
export const options = new DataOption({
  name: 'options',
  label: 'Options',
  tooltip: <>The list of values to select from. Use <b>Option labels</b> to represent the values with alternative text.</>,
});
export const max = new NumberOption({
  name: 'max',
  label: 'Maximum',
  defaultValue: 'auto',
  tooltip: 'Maximum value the input will accept.',
});
export const min = new NumberOption({
  name: 'min',
  label: 'Minimum',
  tooltip: 'Minimum value the input will accept.',
  defaultValue: 'auto',
});
export const step = new NumberOption({
  name: 'step',
  label: 'Step',
  tooltip: (
    <>
      Values written by this input will always be multiples of the step. {}
      <a href={stepHelpUrl} {...linkOpts}>See Help</a> for details.
    </>
  ),
  defaultValue: 'auto',
});
export const interval = new NumberOption({
  name: 'interval',
  label: 'Interval',
  defaultValue: '1', // 1 sec
  tooltip: 'How frequently will this input update (in seconds).',
  min: MIN_TIMER_INTERVAL,
});
export const format = new FormatOption({
  name: 'format',
  label: 'Number format',
  options: formatOptions,
  tooltip: 'The number format code by which data values are formatted.',
  help: <>See <a href={formatHelpUrl} {...linkOpts}>Excel documentation</a></>,
});
export const formatChange = new FormatOption({
  name: 'formatChange',
  label: 'Number format for change',
  options: formatOptions,
  tooltip: 'The number format code by which data values are formatted.',
  help: <>See <a href={formatHelpUrl} {...linkOpts}>Excel documentation</a></>,
});
export const formatLines = new FormatOption({
  name: 'formatLines',
  label: 'Line number format',
  options: formatOptions,
  tooltip: 'The number format code by which data values are formatted.',
  help: <>See <a href={formatHelpUrl} {...linkOpts}>Excel documentation</a></>,
});
export const showHeaders = new BoolOption({
  name: 'headers',
  label: 'Headers',
  defaultValue: 'true',
  tooltip: 'Whether to display the A..Z column headers and 1..99 row headers.',
});
export const optSheetTabs = new BoolOption({
  name: 'sheetTabs',
  label: 'Sheet tabs',
  defaultValue: 'false',
  tooltip: 'Whether to display tabs for the sheets in the target workbook.',
});
export const searchable = new BoolOption({
  name: 'searchable',
  label: 'Searchable',
  defaultValue: 'false',
  tooltip: 'Whether to display a search box above the table. The search box searches all values in the table.',
});
export const sortable = new BoolOption({
  name: 'sortable',
  label: 'Sortable',
  defaultValue: 'false',
  tooltip: 'Makes table sortable by clicking its column headers.',
});
export const tableHeight = new NumberOption({
  name: 'tableHeight',
  label: 'Height limit (in lines)',
  tooltip: 'Limits the number of table rows displayed without scrolling.',
});
export const useCellStyles = new BoolOption({
  name: 'useCellStyles',
  label: 'Use cell styles',
  defaultValue: 'true',
  tooltip: "Use the spreadsheet's cell styles (bold, italic, etc) in the table body",
});
export const showHiddenCells = new BoolOption({
  name: 'showHiddenCells',
  label: 'Include hidden cells',
  defaultValue: 'false',
  tooltip: "Show the spreadsheet's hidden rows and columns in the table",
});
export const optIncludeEmpty = new BoolOption({
  name: 'includeEmpty',
  label: 'Include empty rows and columns',
  defaultValue: 'false',
  tooltip: 'Do not remove empty rows or columns from the right and bottom of the data selection.',
});
export const boldText = new BoolOption({
  name: 'bold',
  label: 'Bold',
  defaultValue: 'false',
  tooltip: 'Bold',
});
export const italicText = new BoolOption({
  name: 'italic',
  label: 'Italic',
  defaultValue: 'false',
  tooltip: 'Italic',
});
export const codeText = new BoolOption({
  name: 'code',
  label: 'Code',
  defaultValue: 'false',
  tooltip: 'Mark as code',
});
export const fontSize = new NumberOption({
  name: 'fontSize',
  label: 'Font size',
  defaultValue: '19',
  tooltip: 'Font size',
  min: MIN_FONT_SIZE,
  max: MAX_FONT_SIZE,
});
export const color = new ColorOption({
  name: 'color',
  label: 'Color',
  tooltip: 'Formula output color',
  placeholder: 'Type color code or select hue',
});
export const rulerColor = new ColorOption({
  ...color,
  tooltip: 'Applies a color to the divider.',
});
export const optBorderColor = new ColorOption({
  name: 'borderColor',
  label: 'Border color',
  placeholder: 'No border',
  tooltip: 'Applies selected color to image border.',
});
export const optBoxShadow = new BoolOption({
  name: 'shadow',
  label: 'Shadow',
  defaultValue: 'false',
  tooltip: 'Applies a shadow effect to the element.',
});
export const optVerticalPadding = new NumberOption({
  name: 'vPadding',
  defaultValue: '0',
  label: 'Vert. padding',
  tooltip: 'Additional vertical padding for the container.',
  min: 0,
  max: 50,
  step: 1,
});
export const optHorizontalPadding = new NumberOption({
  name: 'hPadding',
  defaultValue: '0',
  label: 'Horiz. padding',
  tooltip: 'Additional horizontal padding for the container.',
  min: 0,
  max: 50,
  step: 1,
});
export const optButtonFontSize = new NumberOption({
  name: 'fontSize',
  label: 'Font size',
  defaultValue: '15',
  min: MIN_BUTTON_FONT_SIZE,
  max: MAX_BUTTON_FONT_SIZE,
});
export const optCornerRounding = new NumberOption({
  name: 'rounding',
  label: 'Corner rounding',
  defaultValue: '50%',
  format: '0%',
  min: 0,
  max: 1,
  step: 0.01,
});
export const optCornerRoundingLayout = new NumberOption({
  name: 'rounding',
  label: 'Corner rounding',
  defaultValue: '50%',
  format: '0%',
  min: 0,
  max: 1,
  step: 0.01,
});
export const backgroundColor = new ColorOption({
  name: 'backgroundColor',
  label: 'Background color',
  tooltip: 'Applies a background color to the element.',
  placeholder: 'Same as document',
});
export const optButtonColor = new ColorOption({
  name: 'backgroundColor',
  label: 'Color',
  tooltip: 'Applies a background color to the button.',
  placeholder: 'Type color code or select hue',
});
export const horizontalAlign = new EnumOption({
  name: 'align',
  label: 'Horizontal alignment',
  options: [
    { value: '', label: 'Auto' },
    { value: 'left', label: 'Left' },
    { value: 'center', label: 'Center' },
    { value: 'right', label: 'Right' },
  ],
  tooltip: 'Sets the horizontal alignment of the element.',
});
export const optAlignValue = new EnumOption({
  ...horizontalAlign,
  label: 'Value alignment',
  tooltip: 'Sets the horizontal alignment of the element\'s value.',
});
export const verticalAlign = new EnumOption({
  name: 'vAlign',
  label: 'Vertical alignment',
  defaultValue: 'top',
  options: [
    { value: 'top', label: 'Top' },
    { value: 'middle', label: 'Middle' },
    { value: 'bottom', label: 'Bottom' },
  ],
  tooltip: 'Sets the vertical alignment of the element.',
});
export const textAlign = new EnumOption({
  name: 'align',
  label: 'Text alignment',
  options: [
    { value: '', alias: 'left', label: 'Left' },
    { value: 'center', label: 'Center' },
    { value: 'right', label: 'Right' },
  ],
  tooltip: 'Sets the horizontal alignment of the text.',
});
export const textBlockType = new EnumOption({
  name: 'type',
  label: 'Text type',
  options: [
    { value: 'p', label: 'Paragraph' },
    { value: 'h1', label: 'Heading 1' },
    { value: 'h2', label: 'Heading 2' },
    { value: 'h3', label: 'Heading 3' },
    { value: 'h4', label: 'Heading 4' },
    { value: 'ol', label: 'Ordered list item' },
    { value: 'ul', label: 'Unordered list item' },
    { value: 'quote', label: 'Quotation' },
    { value: 'code', label: 'Code block' },
  ],
  tooltip: 'Sets the type of the text block.',
  disableFx: true,
});
export const layoutWidth = new EnumOption({
  name: 'size',
  label: 'Layout width',
  options: [
    { value: '', label: 'Default' },
    { value: 'wide', label: 'Extra wide' },
    { value: 'full', label: 'Full width' },
  ],
  tooltip: 'Sets the width of the element.',
});
export const actionButtonType = new EnumOption({
  name: 'buttonType',
  label: 'Type',
  options: [
    { value: '', label: 'Select action' },
    { value: 'present', label: 'Enable presentation mode' },
    { value: 'copyurl', label: 'Copy URL' },
    { value: 'openurl', label: 'Open URL' },
    { value: 'pdf', label: 'Save to PDF' },
  ],
  tooltip: 'Sets the type of action button',
  disableFx: true,
});
export const rulerStyle = new EnumOption({
  name: 'rulerStyle',
  label: 'Style',
  defaultValue: 'solid',
  options: [
    { value: 'solid', label: 'Solid line' },
    { value: 'dashed', label: 'Dashed line' },
    { value: 'circles', label: 'Circles' },
    { value: 'squiggle', label: 'Squiggle' },
  ],
  tooltip: 'Sets the style of the divider.',
});
export const rulerWeight = new EnumOption({
  name: 'rulerWeight',
  label: 'Weight',
  options: [
    { value: '', alias: 'default', label: 'Default' },
    { value: 'heavy', label: 'Heavy' },
  ],
  tooltip: 'Sets the thickness of the divider.',
});
export const rulerFullWidth = new BoolOption({
  name: 'rulerFullWidth',
  label: 'Full width',
  defaultValue: 'true',
  tooltip: 'Should the divider be the full width of its container?',
});
export const actionButtonUrlPrefix = new UrlOption({
  label: 'URL prefix',
  tooltip: 'The URL prefix to use in generated links',
  name: 'urlPrefix',
});
export const actionButtonTargetOrigin = new StringOption({
  name: 'targetOrigin',
  label: 'Target Origin',
  tooltip: 'Which target origin to restrict postMessage call to',
  defaultValue: '*',
  help: 'Can be set to *, or any URL (http://domain.com)',
});
export const submitButtonType = new EnumOption({
  name: 'buttonType',
  label: 'Action type',
  options: [
    { value: '', label: 'Select action' },
    { value: 'email', label: 'Send email' },
    { value: 'zapier', label: 'Send data to Zapier' },
  ],
  tooltip: 'Controls the action of the submit button',
});
export const submitButtonSubject = new StringOption({
  name: 'subject',
  label: 'Email subject',
  tooltip: 'Specify the subject line for the email',
});
export const piehole = new BoolOption({
  name: 'piehole',
  label: 'Donut chart',
  defaultValue: 'false',
  tooltip: 'Pie chart with a hole in the middle',
});
export const totalValues = new StringOption({
  name: 'totalValues',
  label: 'Treat values as total',
  options: [
    { value: '', alias: 'none', label: 'None' },
    { value: 'first', label: 'First value' },
    { value: 'last', label: 'Last value' },
    { value: 'both', label: 'First and last values' },
  ],
  tooltip: 'Determines which columns are treated as totals. Those will be colored differently and drawn on the baseline of the chart.',
});
export const dataCategories = new DataOption({
  name: 'exprCategories',
  label: 'Categories',
  tooltip: 'Use categories to group data by color.',
});
export const optDebugOffset = new NumberOption({
  name: 'offset',
  label: 'Offset value',
  defaultValue: '0',
});

// table input controls
export const tableColumnType = new EnumOption({
  name: 'type',
  label: 'Input type',
  defaultValue: '',
  options: [
    { value: 'input', label: 'Input' },
    { value: 'inputbox', label: 'Input box' },
    { value: 'slider', label: 'Slider' },
    { value: 'dropdown', label: 'Dropdown' },
    { value: 'checkbox', label: 'Checkbox' },
    { value: 'tangle', label: 'Interactive value' },
  ],
  tooltip: 'Values in the cell will be replaced with an element of this type.',
  placeholder: 'Choose an input type',
});
export const tableColumnIndex = new NumberOption({
  name: 'index',
  label: 'Column index',
  defaultValue: '1',
  tooltip: 'The number of the column these options should apply to.',
});
export const optTableInputs = new OptionList({
  name: 'series',
  label: 'Column Inputs',
  shape: {
    type: tableColumnType,
    index: tableColumnIndex,
    format: format,
    min: min,
    max: max,
    step: step,
    value: value,
    valueOff: valueOff,
    options: options,
    labels: optionLabels,
  },
});
export const optCfMinValue  = new NumberOption({
  name: 'minValue',
  label: 'Value',
  placeholder: '0%',
  hideLabel: true,
});
export const optCfMinColor  = new ColorOption({
  name: 'minBackgroundColor',
  label: 'Color',
  defaultValue: gridPal2[0],
  tooltip: 'Formula output color',
  placeholder: 'Select a color',
  disableFx: true,
  hideLabel: true,
});
export const optCfMinType  = new EnumOption({
  name: 'minType',
  label: 'Min value',
  defaultValue: 'min',
  options: [
    { value: 'min', label: 'Min value' },
    { value: 'number', label: 'Number' },
    { value: 'percent', label: 'Percent' },
    { value: 'percentile', label: 'Percentile' },
  ],
  disableFx: true,
  hideLabel: true,
});
export const optCfMidValue  = new NumberOption({
  name: 'midValue',
  label: 'Value',
  placeholder: '50%',
  hideLabel: true,
});
export const optCfMidColor  = new ColorOption({
  name: 'midBackgroundColor',
  label: 'Color',
  tooltip: 'Formula output color',
  placeholder: 'Select a color',
  defaultValue: '#ffffff',
  disableFx: true,
  hideLabel: true,
});
export const optCfMidType  = new EnumOption({
  name: 'midType',
  label: 'Mid value',
  defaultValue: 'none',
  options: [
    { value: 'none', label: 'None' },
    { value: 'number', label: 'Number' },
    { value: 'percent', label: 'Percent' },
    { value: 'percentile', label: 'Percentile' },
  ],
  disableFx: true,
  hideLabel: true,
});
export const optCfMaxValue  = new NumberOption({
  name: 'maxValue',
  label: 'Value',
  placeholder: '100%',
  hideLabel: true,
});
export const optCfMaxColor  = new ColorOption({
  name: 'maxBackgroundColor',
  label: 'Color',
  tooltip: 'Formula output color',
  placeholder: 'Select a color',
  defaultValue: gridPal2[1],
  disableFx: true,
  hideLabel: true,
});
export const optCfMaxType  = new EnumOption({
  name: 'maxType',
  label: 'Type',
  defaultValue: 'max',
  options: [
    { value: 'max', label: 'Max value' },
    { value: 'number', label: 'Number' },
    { value: 'percent', label: 'Percent' },
    { value: 'percentile', label: 'Percentile' },
  ],
  disableFx: true,
  hideLabel: true,
});
export const optColorScale = new OptionList({
  name: 'colorScale',
  label: 'Color scale',
  shape: {
    minType: optCfMinType,
    minValue: optCfMinValue,
    minBackgroundColor: optCfMinColor,
    midType: optCfMidType,
    midValue: optCfMidValue,
    midBackgroundColor: optCfMidColor,
    maxType: optCfMaxType,
    maxValue: optCfMaxValue,
    maxBackgroundColor: optCfMaxColor,
  },
});

// annotations
export const annoType = new EnumOption({
  name: 'type',
  label: 'Annotation type',
  defaultValue: '',
  placeholder: 'Select annotation type',
  options: [
    { value: 'point', label: 'Data point' },
    { value: 'xline', label: 'Line on X-axis' },
    { value: 'yline', label: 'Line on Y-axis' },
    // the following (incomplete types) are disabled for now
    // { value: 'xrange', label: 'X-axis interval' },
    // { value: 'yrange', label: 'Y-axis interval' },
    // { value: 'area', label: 'A rectangle' },
  ],
  tooltip: 'The type of annotation to draw.',
});
export const annoTitle = new DataOption({
  name: 'title',
  label: 'Label text',
  tooltip: 'The text displayed next to the annotation marker.',
  enforceExpression: false,
});
export const annoVisibility = new BoolDataOption({
  name: 'visible',
  label: 'Visibility',
  defaultValue: 'true',
  tooltip: 'Is the annotation shown or hidden?',
});
export const annoDataStart = new DataOption({
  name: 'dataStart',
  label: 'X-axis position',
  tooltip: 'The horizontal position of the annotation marker.',
  enforceExpression: false,
});
export const annoDataEnd = new DataOption({
  name: 'dataEnd',
  label: 'X-axis end',
  tooltip: 'The horizontal position where the annotation marker ends.',
  enforceExpression: false,
});
export const annoValueStart = new DataOption({
  name: 'valueStart',
  label: 'Y-axis position',
  tooltip: 'The vertical position of the annotation marker.',
  enforceExpression: false,
});
export const annoValueEnd = new DataOption({
  name: 'valueEnd',
  label: 'Y-axis end',
  tooltip: 'The vertical position where the annotation marker ends.',
  enforceExpression: false,
});
export const optAnnotations = new OptionList({
  name: 'annotations',
  label: 'Annotations',
  shape: {
    type: annoType,
    title: annoTitle,
    visible: annoVisibility,
    x1: annoDataStart,
    x2: annoDataEnd,
    y1: annoValueStart,
    y2: annoValueEnd,
  },
});
// Bar charts flip the meaning of x and y but basically behave the same as the basic
// annotations. These options are just relabeling the options.
export const annoTypeBar = new EnumOption({
  name: 'type',
  label: 'Annotation type',
  defaultValue: '',
  placeholder: 'Select annotation type',
  options: [
    { value: 'point', label: 'Data point' },
    { value: 'xline', label: 'Line on Y-axis' },
    { value: 'yline', label: 'Line on X-axis' },
    // the following (incomplete types) are disabled for now
    // { value: 'xrange', label: 'X-axis interval' },
    // { value: 'yrange', label: 'Y-axis interval' },
    // { value: 'area', label: 'A rectangle' },
  ],
  tooltip: 'The type of annotation to draw.',
});
export const annoDataStartBar = new DataOption({
  name: 'dataStart',
  label: 'Y-axis position',
  tooltip: 'The vertical position of the annotation marker.',
  enforceExpression: false,
});
export const annoDataEndBar = new DataOption({
  name: 'dataEnd',
  label: 'Y-axis end',
  tooltip: 'The vertical position where the annotation marker ends.',
  enforceExpression: false,
});
export const annoValueStartBar = new DataOption({
  name: 'valueStart',
  label: 'X-axis position',
  tooltip: 'The horizontal position of the annotation marker.',
  enforceExpression: false,
});
export const annoValueEndBar = new DataOption({
  name: 'valueEnd',
  label: 'X-axis end',
  tooltip: 'The horizontal position where the annotation marker ends.',
  enforceExpression: false,
});
// scatter-plot variant of annotations
export const annoValue2Start = new DataOption({
  name: 'value2Start',
  label: 'X-axis position',
  enforceExpression: false,
  tooltip: 'The horizontal position of the annotation marker.',
});
export const annoValue2End = new DataOption({
  name: 'value2End',
  label: 'X-axis end',
  tooltip: 'The horizontal position of the annotation marker.',
  enforceExpression: false,
});
export const optAnnotationsScatter = new OptionList({
  name: 'annotations',
  label: 'Annotations',
  shape: {
    type: annoType,
    title: annoTitle,
    visible: annoVisibility,
    x1: annoValue2Start,
    x2: annoValue2End,
    y1: annoValueStart,
    y2: annoValueEnd,
  },
});
export const optAccentColor = new ColorOption({
  name: 'accentColor',
  label: 'Accent color',
  tooltip: 'Applies accent color where applicable, e.g. interaction elements.',
  placeholder: '#282b3e',
});
export const optDocBackgroundColor = new ColorOption({
  name: 'backgroundColor',
  label: 'Background color',
  tooltip: 'Applies a custom background color.',
  placeholder: '#ffffff',
});
export const optTitleFont = new EnumOption({
  name: 'titleFont',
  label: 'Headings',
  tooltip: 'Applies selected font to headings in the document.',
  defaultValue: DEFAULT_TITLE_FONT.value,
  options: fonts,
  disableFx: true,
});
export const optBodyFont = new EnumOption({
  name: 'bodyFont',
  label: 'Body text',
  tooltip: 'Applies selected font to text in the document body.',
  defaultValue: DEFAULT_BODY_FONT.value,
  options: fonts,
  disableFx: true,
});
export const optChartFont = new EnumOption({
  name: 'chartFont',
  label: 'Charts and tables',
  tooltip: 'Applies selected font to text in charts and tables.',
  defaultValue: DEFAULT_BODY_FONT.value,
  options: fonts,
  disableFx: true,
});
export const optDocDescription = new StringOption({
  name: 'description',
  label: 'Document description',
  help: 'Optional (max 180 characters).',
  tooltip: 'A short description of your document in any list of documents.',
  placeholder: 'What\'s your document about?',
  disableFx: true,
  // maxLength: 180,
  // textarea: true,
  // rows: 3,
});
export const optLanguage = new EnumOption({
  name: 'language',
  label: 'Language',
  tooltip: 'How numbers are represented in your document, e.g. decimal points and thousands separators. Please note that the language setting will NOT change the language or syntax in the formula language.',
  defaultValue: 'en-US',
  options: languages,
  disableFx: true,
});
export const optClassicCharts = new BoolOption({
  name: 'classicCharts',
  label: 'Use Mediterranean chart style',
  tooltip: 'Formats charts in Mediterranean style & palette.',
  disableFx: true,
});
export const optShowViewChrome = new EnumOption({
  name: 'showViewChrome',
  label: 'Display Calculator Studio UI',
  tooltip: 'Hides Calculator Studio UI when the content is being viewed via link.',
  defaultValue: 'all',
  options: [
    { value: 'all', label: 'Shown' },
    { value: 'none', label: 'Hidden' },
  ],
  help: <span>Affects projects for viewers <em>other than the author</em>.</span>,
  disableFx: true,
});
export const optFormFieldName = new StringOption({
  name: 'name',
  label: 'Name',
  tooltip: 'The name of the field in the form data.',
  enforceExpression: false,
});
export const optFormFieldValue = new ValueOption({
  name: 'value',
  label: 'Cell',
  tooltip: 'The cell from which to read the value.',
  enforceExpression: true,
});
export const optFormFields = new OptionList({
  name: 'fields',
  label: 'Form fields',
  shape: {
    name: optFormFieldName,
    value: optFormFieldValue,
  },
});
export const optEmailEnabled = new BoolOption({
  name: 'emailEnabled',
  label: 'Send Email',
  tooltip: 'Send form submissions to email',
});
export const optEmailAddress = new StringOption({
  name: 'emailAddress',
  label: 'Recipient',
  tooltip: 'Enter email address to receive form submissions. If left blank, the email will be sent to the document owner.',
  placeholder: 'Enter email address',
});
export const optEmailSubject = new StringOption({
  name: 'emailSubject',
  label: 'Email subject',
  tooltip: 'Specify the subject line for the email',
});
export const optCssImports = new StringOption({
  name: 'externalCss',
  label: 'CSS imports',
  tooltip: 'Custom CSS style declarations to include.',
  disableFx: true,
});
export const optCustomStyle = new StringOption({
  name: 'customStyle',
  label: 'Custom Document Style',
  tooltip: 'Custom theme style properties to include.',
  disableFx: true,
});
export const optHubSpotEnabled = new BoolOption({
  name: 'hubspotEnabled',
  label: 'Send to HubSpot',
  tooltip: 'Sync form data with contacts in HubSpot',
});
export const optHubSpotIntegrationId = new StringOption({
  name: 'hubspotIntegrationId',
  label: 'HubSpot Account',
  help: 'This is intended to be hidden and automatically set',
});

// NB: This mirrors buildCommonButtonUIOptions() in options/builders.txt
export const commonButtonUI = {
  [optCornerRounding.name]: optCornerRounding,
  [optButtonFontSize.name]: optButtonFontSize,
  [optInlineSize.name]: optInlineSize,
  [optButtonColor.name]: optButtonColor,
  [optWidthAuto.name]: optWidthAuto,
};
