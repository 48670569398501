import { defaultPalette, extendPal } from '@/grid/chart/utils/color';
import { ACCENTCOLOR, BACKGROUNDCOLOR, TEXTCOLOR } from '@/grid/constants';
import { validExpr } from '@/grid/utils';

import { OptionError } from '../OptionError';
import { isBlank } from '../utils/isBlank';
import { isFormula } from '../utils/isFormula';
import { isValidColor } from '../utils/isValidColor';
import { validateFormula } from '../utils/validateFormula';
import { GridOption } from './GridOption';

export const isOnlyHexPalette = value => (
  /^=(?=\{)(?:\s*[{,]\s*"#(?:[a-f\d]{6}|[a-f\d]{3})")*\s*\}$/i.test(value)
);

/**
 * Class representing a "Color palette" GRID element option.
 * Values are expected to be formula for an Excel array of valid colors (see ColorOption).
 * @augments GridOption
 */
export class PaletteOption extends GridOption {
  type = 'palette';

  isPlainOption = value => {
    return (
      isBlank(value) ||
      String(value).toLowerCase() === TEXTCOLOR ||
      isOnlyHexPalette(value)
    );
  };

  /**
   * Validate a raw value as a palette or a formula, or blank.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @return {OptionError|null} Error object or null if valid.
   */
  validate (value) {
    if (isBlank(value)) {
      return null;
    }
    const str = String(value);
    if (isFormula(str)) {
      return validateFormula(str);
    }
    if (isValidColor(str)) {
      return null;
    }
    return new OptionError();
  }

  parse () {
    return null;
  }

  /**
   * Read an option value from a props object and return a valid palette array.
   *
   * There are essentially two cases for interaction with this option:
   *
   * For most basic charts: For these we want options like this:
   *    { minColors: 7, autoExtend: true, default: chartTheme.palette }
   * Because user may enter a single color (in which case we append default)
   * and we want there to be a decent palette for the chart. We also want to
   * automatically extend the palette by adding two rounds of shades to it
   * with progressively more white mixed in so we don't repeat a color until
   * the 22nd one is reached.
   *
   * The second case is for specialized charts such as Waterfall or a KPI where
   * we would call this with:
   *    { minColors: 2, autoExtend: false, default: [ green, gray, red ] }
   * As only 3 colors are ever needed and a separate default palette is used.
   *
   * @param {object} props - A props collection to read from.
   * @return {string[]} The option value or its default value.
   */
  read = (props, options = {}) => {
    const colors = [];
    const propValue = this.readRaw(props);
    let extendSingle = false;
    if (props.model && validExpr(propValue)) {
      const lcColor = String(propValue).toLowerCase();
      if (lcColor === TEXTCOLOR || lcColor === BACKGROUNDCOLOR || lcColor === ACCENTCOLOR) {
        colors.push(lcColor);
        extendSingle = true;
      }
      else if (propValue && isValidColor(propValue)) {
        // allow a single color: #400080
        colors.push(propValue);
      }
      else {
        const cells = props.model.readCells(propValue);
        if (cells && cells.length) {
          cells.forEach(row => {
            row.forEach(cell => {
              const colorValue = cell && cell.v;
              if (isValidColor(colorValue, true)) {
                colors.push(colorValue);
              }
              else {
                // the color is not valid, so we instead add a faded gray color to indicate
                // to the user that it is invalid
                colors.push('rgba(0,0,0, 0.2)');
              }
            });
          });
        }
      }
    }
    // ensure that the color scheme we end up with has the required minimum of colors:
    if (!colors.length || (options.minColors && colors.length < options.minColors)) {
      if (extendSingle) {
        while (colors.length < options.minColors) {
          colors.push(colors[0]);
        }
      }
      else {
        colors.push(...(options.default || defaultPalette));
      }
    }
    // repeat the scheme twice (mixing in white) to expand the scheme if wanted
    if (options.autoExtend) {
      const scheme = [ ...colors ];
      colors.push(...extendPal(scheme, 0.5));
      colors.push(...extendPal(scheme, 0.75));
    }
    return colors;
  };
}
