import EventEmitter from 'component-emitter';

import { sessionId } from '@/api/session';
import { changeNotificationClient } from '@/api/watching';
import { ms } from '@/utils/time';

/** @typedef {import('@/api/watching').WorkbookInfo} WorkbookInfo */
/** @typedef {import('@/api/watching').WorkbookModified} WorkbookModified */

export class WbMonitor extends EventEmitter {
  constructor (client = changeNotificationClient) {
    super();

    /** @type {WorkbookInfo[]} */
    this._watching = [];

    /** @type {import('@/api/watching').WebSocketClient} */
    this.client = client;

    client.on('connect', () => {
      this._watching.forEach(wbInfo => {
        client.watchWorkbook(wbInfo);
      });
    });
    client.on('workbook_updated', (/** @type {WorkbookModified} */ data) => {
      if (this.isWatching(data.id) && this._isFromOtherSession(data)) {
        this.emit('update', data);
      }
    });
    this.pingInterval = setInterval(() => this.ping(), ms.minute);
  }

  _isFromOtherSession = (/** @type {WorkbookModified} */ data) => {
    return data.session_id !== sessionId;
  };

  /**
   * @param {string} wbId
   */
  isWatching (wbId) {
    return !!this._watching.find(d => d.workbookId === wbId);
  }

  watchList () {
    return [ ...this._watching ];
  }

  unwatchAll () {
    for (const wbInfo of this.watchList()) {
      this.unwatch(wbInfo);
    }
    clearInterval(this.pingInterval);
    return this;
  }

  watch = (/** @type {WorkbookInfo} */ wbInfo) => {
    if (!wbInfo || !wbInfo.workbookId) {
      return;
    }
    // don't need to re-watch the same source
    if (this.isWatching(wbInfo.workbookId)) {
      return;
    }
    this._watching.push(wbInfo);
    this.client.watchWorkbook(wbInfo);
    if (wbInfo.shouldPing) {
      this.client.pingWorkbook(wbInfo);
    }
    return this;
  };

  /**
   * @param {WorkbookInfo} wbInfo
   */
  unwatch (wbInfo) {
    const index = this._watching.findIndex(_wbInfo => _wbInfo.workbookId === wbInfo.workbookId);
    if (index !== -1) {
      this._watching.splice(index, 1);
      this.client.unwatchWorkbook(wbInfo.workbookId);
    }
    return this;
  }

  ping () {
    for (const wbInfo of this.watchList()) {
      if (wbInfo.shouldPing) {
        this.client.pingWorkbook(wbInfo);
      }
    }
  }
}
