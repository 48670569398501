import { isValidFormat } from 'numfmt';

import { OptionError } from '../OptionError';
import { isBlank } from '../utils/isBlank';
import { isFormula } from '../utils/isFormula';
import { validateFormula } from '../utils/validateFormula';
import { StringOption } from './StringOption';

/**
 * Class representing a "Excel number format string" GRID element option.
 * Values are expected to be qualified Excel number formatting code.
 * @augments StringOption
 */
export class FormatOption extends StringOption {
  type = 'format';

  isPlainOption = value => {
    return isBlank(value) || this.options.some(option => option.value === value) || false;
  };

  /**
   * Validate a raw value as being a formula, or a valid Excel number format
   * string, or blank.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @return {OptionError|null} Error object or null if valid.
   */
  validate (value) {
    if (isBlank(value)) {
      return null;
    }
    const str = String(value);
    if (isFormula(str)) {
      return validateFormula(str);
    }
    return isValidFormat(str)
      ? null
      : new OptionError('Invalid number format');
  }

  /**
   * Parse a value as a number format string.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @return {string}
   */
  parse (value) {
    return isBlank(value) ? '' : String(value);
  }
}
