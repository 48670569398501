import { isBlank } from '../utils/isBlank';
import { BoolOption } from './BoolOption';
import { DataOption } from './DataOption';

/**
 * An option that encapsulates an area of booleans, unlike a boolean option
 * which represents a single boolean. This is essentially a "hack" to expose
 * annotation visibility as a toggle, despite reading it as an array.
 *
 * should validate as a bool option
 * should isPlainOption as a bool option
 * should parse as a bool option
 */
export class BoolDataOption extends DataOption {
  type = 'bool';

  constructor (opts) {
    super(opts);
    // DataOption sets enforceExpression to true, which is not what we want here
    if (opts.enforceExpression == null) {
      this.enforceExpression = false;
    }
  }

  /** @type {'' | 'false' | 'true' | undefined}*/
  defaultValue;

  /**
   * Validate a value as a boolean.
   * @param {string} value - An option value.
   * @return {import('../OptionError').OptionError|null} Error object or null if valid.
   */
  validate (value) {
    return BoolOption.prototype.validate.call(this, value);
  }

  /**
   * Report if a value is a boolean string (or blank). When true, the option
   * may be presented as a toggle.
   * @param {string} value - An option value.
   * @return {boolean}
   */
  isPlainOption = value => {
    return isBlank(value) || /^(true|false)$/i.test(value);
  };

  /**
   * Parse a value as a boolean.
   * @param {string|null|undefined} value - An option value.
   * @return {boolean} The most reasonable representation of the value.
   */
  parse (value) {
    return BoolOption.prototype.parse.call(this, value);
  }
}
