export const DEFAULT_TITLE_FONT = {
  value: 'Poppins',
  label: 'Poppins (heading default)',
};
export const DEFAULT_BODY_FONT = {
  value: 'Nunito',
  label: 'Nunito (body default)',
};

export const fonts = [
  DEFAULT_TITLE_FONT,
  DEFAULT_BODY_FONT,
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Proxima Nova', label: 'Proxima Nova' },
  // Google Fonts
  { value: 'Arvo', label: 'Arvo' },
  { value: 'Bodoni Moda', label: 'Bodoni Moda' },
  { value: 'Cabin', label: 'Cabin' },
  { value: 'Chivo', label: 'Chivo' },
  { value: 'Courier Prime', label: 'Courier Prime' },
  { value: 'DM Sans', label: 'DM Sans' },
  { value: 'Domine', label: 'Domine' },
  { value: 'EB Garamond', label: 'EB Garamond' },
  { value: 'Fira Sans', label: 'Fira Sans' },
  { value: 'Inter', label: 'Inter' },
  { value: 'Inter Light', label: 'Inter Light' },
  { value: 'Josefin Sans', label: 'Josefin Sans' },
  { value: 'Libre Baskerville', label: 'Libre Baskerville' },
  { value: 'Libre Franklin', label: 'Libre Franklin' },
  { value: 'Lora', label: 'Lora' },
  { value: 'Merriweather', label: 'Merriweather' },
  { value: 'Montserrat', label: 'Montserrat' },
  { value: 'Nunito', label: 'Nunito' },
  { value: 'Overpass', label: 'Overpass' },
  { value: 'Open Sans', label: 'Open Sans' },
  { value: 'Open Sans Medium', label: 'Open Sans Medium' },
  { value: 'Playfair Display', label: 'Playfair Display' },
  { value: 'Poppins', label: 'Poppins' },
  { value: 'Raleway', label: 'Raleway' },
  { value: 'Roboto', label: 'Roboto' },
  { value: 'Roboto Slab', label: 'Roboto Slab' },
  { value: 'Rubik', label: 'Rubik' },
  { value: 'Source Sans Pro', label: 'Source Sans Pro' },
  { value: 'Source Serif Pro', label: 'Source Serif Pro' },
  { value: 'Space Mono', label: 'Space Mono' },
  { value: 'Work Sans', label: 'Work Sans' },
];
