import { color as d3_color } from 'd3-color';

import { ACCENTCOLOR, BACKGROUNDCOLOR, TEXTCOLOR } from '@/grid/constants';

/**
 * Determines whether the specified option value is a valid color. Accepts 3 and
 * 6 digit hex codes as well as all HTML/CSS named colors.
 *
 * @param {any} value An option value
 * @return {boolean} True if the specified value is a valid color, False otherwise.
 */
export function isValidColor (value, allowThemeColors = false) {
  const lcValue = String(value).toLowerCase();
  // allow: #000, #000000
  if (/^#(?:[a-f\d]{3}|[a-f\d]{6})$/.test(lcValue)) {
    return true;
  }
  // allow named colors: mistyrose, rosybrown, lemonchiffon,...
  if (/^(?:[a-z]{3,})$/.test(lcValue) && d3_color(lcValue)) {
    return true;
  }
  // allow "accentColor", "textColor", and "backgroundColor"
  if (allowThemeColors && (
    lcValue === TEXTCOLOR ||
    lcValue === ACCENTCOLOR ||
    lcValue === BACKGROUNDCOLOR
  )) {
    return true;
  }
  return false;
}
