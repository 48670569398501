import { OptionError } from '../OptionError';
import { isBlank } from '../utils/isBlank';
import { isFormula } from '../utils/isFormula';
import { typeCast } from '../utils/typeCast';
import { validateFormula } from '../utils/validateFormula';
import { GridOption } from './GridOption';

/**
 * Class representing a "Boolean" GRID element option.
 * @augments GridOption
 */
export class BoolOption extends GridOption {
  type = 'bool';

  /** @param {import('./GridOption').GridOptionArgs} opts */
  constructor (opts) {
    super(opts);
    /** @type {'' | 'false' | 'true'} [defaultValue] */
    this.defaultValue;
  }

  /**
   * Read an option value and parse it as a boolean.
   * @param {object} props - A props collection to read from.
   * @return {boolean} The option value or its default value.
   */
  read = props => {
    const cell = this.readCell(props);
    return this.parse(cell ? cell.v : null);
  };

  /**
   * Validate a value as a boolean.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @return {OptionError | null} Error object or null if valid.
   */
  validate (value) {
    if (isBlank(value)) {
      return null;
    }
    const str = String(value);
    if (isFormula(str)) {
      return validateFormula(str);
    }
    if (/^(true|false)$/i.test(str)) {
      return null;
    }
    return new OptionError();
  }

  /**
   * Report if a value is a boolean string (or blank). When true, the option
   * may be presented as a toggle.
   * @param {string} value - An option value.
   * @return {boolean}
   */
  isPlainOption = value => {
    return isBlank(value) || /^(true|false)$/i.test(value);
  };

  /**
   * Parse a value as a boolean.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @return {boolean} true if the value is "truthy".
   */
  parse (value) {
    if (value) {
      value = typeCast(value);
    }
    return !!value;
  }
}
