import FormulaError from './excel/FormulaError';

type BorderStyle =
  | 'none'
  | 'dashDot'
  | 'dashDotDot'
  | 'dashed'
  | 'dotted'
  | 'double'
  | 'hair'
  | 'medium'
  | 'mediumDashDot'
  | 'mediumDashDotDot'
  | 'mediumDashed'
  | 'slantDashDot'
  | 'thick'
  | 'thin';

interface CellStyle {
  'font-name'?: string,
  'font-size'?: number,
  'font-color'?: string,
  bold?: boolean,
  italic?: boolean,
  underline?: string,
  'fill-color'?: string,
  'border-left-style'?: BorderStyle,
  'border-left-color'?: string,
  'border-right-style'?: BorderStyle,
  'border-right-color'?: string,
  'border-top-style'?: BorderStyle,
  'border-top-color'?: string,
  'border-bottom-style'?: BorderStyle,
  'border-bottom-color'?: string,
  'horizontal-alignment'?:
  | 'general'
  | 'left'
  | 'center'
  | 'right'
  | 'fill'
  | 'justify'
  | 'centerContinuous'
  | 'distributed',
  'vertical-alignment'?: 'bottom' | 'top' | 'center' | 'justify' | 'distributed',
  'wrap-text'?: boolean,
  'shrink-to-fit'?: boolean,
  'number-format'?: string,
  'number-format-from-formula'?: string,
}

export type TableColumnCSF = {
  name: string,
  data_type?: 'text' | 'number' | 'boolean' | 'datetime' | 'unknown',
  formula?: string,
};

export type TableCSF = {
  name: string,
  sheet: string,
  ref: string,
  columns: TableColumnCSF[],
  totals_row_count?: number,
  header_row_count?: number,
};

/**
 * Plain JavaScript objects representing cell contents.
 *
 * See https://github.com/GRID-is/GRID/blob/master/specs/csf.md.
 *
 * Note: despite the name, this type differs a little from the schema of cell
 * information present in API responses, because:
 *
 * - GRID-client replaces the `si` attribute in API responses with looked-up `s`
 *   style objects before passing to Apiary.
 * - this type is also used for setting (and sometimes resetting) cell contents
 *   internally, so some attributes can be `null` which are never `null` in API
 *   responses, and the `v` attribute can be a `FormulaError`, which will not
 *   appear in incoming CSF.
 */
export type CellCSF = {
  /** Formula text (e.g. 'SUM(A1:A4)')*/
  f?: string | null,
  /**
   * Formula type: 'a' = array formula, or absent to mean single-cell formula,
   * in CSF versions after we introduce this in excel-processor. Before that CSF
   * version change, this is always absent in CSF delivered from the GRID API.
   */
  ft?: 'a',

  /**
   * Value held by the cell; this is either a formula result or a literal value.
   * Note that a string representing an error value (such as `#VALUE!`) is
   * interpreted as that error value (with no detail message) when we populate
   * cells from CSF. Yes, this does mean that we cannot currently represent such
   * string values in CSF!
   */
  v?: string | number | boolean | FormulaError | null,
  /** Cell's user-assigned number format */
  z?: string | null,
  /** Cell's formula-assigned number format, propagated from other cells */
  zf?: string | null,
  /**
   * Style index (into styles array, present in API responses but replaced by s
   * by assigning from styles array to individual cells on load
   */
  si?: number,
  /** Styles/theme of the cell */
  s?: object | null,
  /** A URL to an external resource */
  href?: string | null,
  /**
   * Spill range of the cell (e.g. 'A2:B4').
   *
   * Given a cell A1 that spills into A1:C2, every cell in the range (including
   * the A1 cell) should have an `F` value of 'A1:C2'.
   */
  F?: string,
};

export type ColumnInfo = {
  begin: number, // 1-based, 1 corresponds to column A
  end: number, // inclusive
  width: number,
  si?: number,
};

export type DrawingCSF = {
  anchor: AbsoluteAnchor | CellAnchor | TwoCellAnchor,
  chart_id?: string,
};

type AbsoluteAnchor = {
  type: 'absolute',
  position: Position,
  extent: Extent,
};

type CellAnchor = {
  type: 'cell',
  top_left: CellOffset,
  extent: Extent,
};

type TwoCellAnchor = {
  type: 'two_cell',
  top_left: CellOffset,
  bottom_right: CellOffset,
};

type Position = {
  x: StandardMeasure,
  y: StandardMeasure,
};

type Extent = {
  // These are expressed in English Metric Units (EMUs), see below
  x: number,
  y: number,
};

type CellOffset = {
  row: number,
  // The offset within the row.
  row_offset: StandardMeasure,
  column: number,
  // The offset within the column.
  column_offset: StandardMeasure,
};

// A distance measure. When the numerical form is used, then EMUs are assumed.
// The string form can be used to specify another unit.
type StandardMeasure = number | string;

export type SheetCSF = {
  structure?: 'none' | 'datatable',
  name: string,
  cells: {
    [addr: string]: CellCSF,
  },
  hidden?: boolean,
  // deprecated, removed in CSF 4.0
  col_widths?: {
    [colName: string]: number,
  },
  row_heights?: {
    [rowNum: number]: number, // rowNum is 1-based
  },
  defaults?: {
    col_width?: number,
    row_height?: number,
  },
  columns?: ColumnInfo[], // added in CSF 4.0
  merged_cells?: string[],
  gsdv?: string[],
  show_grid_lines?: boolean,
  drawings?: DrawingCSF[],
};

export type NameCSF = {
  name: string,
  value: string,
  scope?: string,
};

export type CloudConnection = {
  automatic_refresh?: 'live' | 'delayed' | 'unavailable',
  cloud_drive_id: string,
  cloud_drive_provider:
  | 'airtable'
  | 'airtable_api_key'
  | 'dropbox'
  | 'google'
  | 'onedrive'
  | 'notion'
  | 'smartsheet'
  | 'url',
  cloud_file_id: string,
  cloud_file_path: string,
  display_name: string,
  href?: string,
  id: string,
  should_ping: boolean,
  state: 'active' | 'inactive' | 'degraded',
};

const _WORKBOOK_TYPES = [
  'airtable',
  'csv',
  'excel',
  'form-submissions',
  'google-sheets',
  'native',
  'unknown',
  'notion',
  'smartsheet',
] as const;

export type WorkbookType = (typeof _WORKBOOK_TYPES)[number];
export const WORKBOOK_TYPES: readonly WorkbookType[] = _WORKBOOK_TYPES;

type ChartType =
  | 'area'
  | 'area3D'
  | 'bar'
  | 'bar3D'
  | 'bubble'
  | 'column'
  | 'column3D'
  | 'doughnut'
  | 'line'
  | 'line3D'
  | 'ofPie'
  | 'pie'
  | 'pie3D'
  | 'radar'
  | 'scatter'
  | 'stock'
  | 'surface'
  | 'surface3D';

type Formula = `=${string}`;
type FormulaOrLiteralString = Formula | string;

type Series = {
  name: FormulaOrLiteralString,
  // This is an array to accommodate multi-dimensional series for scatter and bubble charts
  values: Formula[],
  // For combo charts only - the type of chart that the series belongs to
  chart_type?: ChartType,
};

export type AxisCSF = {
  type: 'category' | 'value' | 'date' | 'series',
  position: 'left' | 'right' | 'top' | 'bottom',
  title?: FormulaOrLiteralString,
  number_format?: string,
  // Indicates if the axis is "reversed". Assume minMax if absent (not reversed).
  orientation?: 'minMax' | 'maxMin',
  min?: number,
  max?: number,
  log_base?: number,
};

export type ChartCSF = {
  id: string,
  type: ChartType | 'combo',
  series: Series[],
  axes?: AxisCSF[],
  title?: FormulaOrLiteralString,
  // If auto_title_deleted is false and title is empty, use the name of the first series as title.
  // Assume the value is true if the attribute is absent.
  auto_title_deleted?: boolean,
  labels?: Formula,
  legend?: {
    position: 'top' | 'bottom' | 'left' | 'right' | 'top-right',
  },
  data_labels?: {
    // If true, then the values of each data point should be displayed on the chart
    values: boolean,
  },
  // Same as  BarGrouping, described in section 21.2.3.4 of ECMA-376
  grouping?: 'standard' | 'stacked' | 'clustered' | 'percentStacked',
  // If true, then the colors within a series should vary by point
  vary_colors?: boolean,
};

export interface WorkbookBody {
  schema_version?: string,
  sheets: SheetCSF[],
  tables?: TableCSF[],
  names?: NameCSF[],
  styles?: CellStyle[],
  calculation_properties?: {
    iterate: boolean,
    iterate_count: number,
    iterate_delta: number,
  },
  metadata?: {
    pruned_sheets?: string[],
    defects?: Record<string, string>,
  },
  charts?: ChartCSF[],
}

/**
 * Unauthoritative abridged version of info part of workbook CSF; for the real
 * thing, see components['schemas']['WorkbookInfo']
 */
export type WorkbookInfo = {
  id: string,
  filename: string,
  owner: {
    id: string,
    name: string,
    username: string,
    bio?: string,
    avatar_url?: string,
  },
  has_draft: boolean,
  creation_time: string,
  update_time: string,
  version?: number,
  defect?: string,
  state: 'uploading' | 'processing' | 'ready' | 'invalid',
  cloud_connection?: CloudConnection,
  type: WorkbookType,
};

export type WorkbookCSF = WorkbookBody & Partial<WorkbookInfo>;

export function assignCellStylesIfNeeded (workbook: WorkbookBody) {
  // As of CSF 3, style information and number formatting is no longer stored directly on a cell
  // To hide this detail from CSF consumers, ensure that the cells still have their .s and .z attributes like they did before
  if (workbook.styles) {
    const stylesWithoutNumberFormat = workbook.styles.map(styleObject => {
      const out = { ...styleObject };
      for (const key of [ 'number-format', 'number-format-from-formula' ]) {
        if (key in out) {
          delete out[key];
        }
      }
      return out;
    });

    for (const sheet of workbook.sheets) {
      for (const cell of Object.values(sheet.cells)) {
        addStyleAttributesToCell(workbook.styles, stylesWithoutNumberFormat, cell);
      }
    }
  }
}

function addStyleAttributesToCell (styles: CellStyle[], stylesWithoutNumberFormat: CellStyle[], cell: CellCSF) {
  const styleIndex = cell.si || 0;
  if (styleIndex >= 0 && styleIndex < styles.length) {
    const styleObject = styles[styleIndex];
    const styleObjectWithoutNumberFormat = stylesWithoutNumberFormat[styleIndex];

    // Do not include number format information in the style objects passed to
    // Apiary. This avoids the problem where `z` is edited to be null when editing,
    // and then the number format from the style object is re-applied to the cell
    // when saving.
    cell.s = styleObjectWithoutNumberFormat;

    if (styleObject['number-format']) {
      cell.z = styleObject['number-format'];
    }
    if (styleObject['number-format-from-formula']) {
      cell.zf = styleObject['number-format-from-formula'];
    }
  }
}
