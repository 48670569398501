import { Model } from '@grid-is/apiary';

import ChartTheme from '@/grid/ChartTheme';
import { optAccentColor, optDocBackgroundColor } from '@/grid/propsData';
import Theme, { baseTheme } from '@/grid/Theme';

/**
 * @typedef Design
 * @property {string} titleFont
 * @property {string} bodyFont
 * @property {string} chartFont
 * @property {string} backgroundColor
 * @property {string} backgroundColorOverride
 * @property {string} accentColor
 */

/**
 * @param {Design} a
 * @param {Design} b
 * @return {boolean}
 */
function isSameDesign (a, b) {
  const props = [
    'titleFont',
    'bodyFont',
    'chartFont',
    'backgroundColor',
    'backgroundColorOverride',
    'accentColor',
  ];
  if (a === b) {
    return true;
  }
  for (const propName of props) {
    const valA = (a[propName] || '').toLowerCase();
    const valB = (b[propName] || '').toLowerCase();
    if (valA !== valB) {
      return false;
    }
  }
  return true;
}

export class ThemeKeeper {
  constructor () {
    /** @type {Model} */
    this.model;

    /** @type {string} */
    this._chartThemeName = ChartTheme.defaultName;

    /** @type {Theme} */
    this.theme = new Theme();

    /** @type {ChartTheme} */
    this.chartTheme = new ChartTheme(ChartTheme.defaultName, {
      foreground: this.theme.color,
      background: this.theme.background,
      font: this.theme.chartFont,
    });

    /** @type {Record<string, string>} */
    this.override = {};

    /** @type {Readonly<Design>} */
    this.props = Object.freeze({
      titleFont: '',
      bodyFont: '',
      chartFont: '',
      backgroundColor: '',
      backgroundColorOverride: '',
      accentColor: '',
    });
  }

  /** @type {string} */
  get chartThemeName () {
    return this._chartThemeName;
  }

  /** @type {string} */
  set chartThemeName (name) {
    if (this._chartThemeName !== name) {
      this._chartThemeName = name;
      this.update();
    }
  }

  /**
   * @param {object} themeProps Design properties to write
   * @param {string} [themeProps.titleFont] The title font
   * @param {string} [themeProps.bodyFont] The body font
   * @param {string} [themeProps.chartFont] The chart font
   * @param {string} [themeProps.accentColor] An accent color
   * @param {string} [themeProps.backgroundColor] A background color
   * @param {string} [themeProps.backgroundColorOverride] A background color
   */
  setProps ({ titleFont, bodyFont, chartFont, accentColor, backgroundColor, backgroundColorOverride }) {
    const props = { ...this.props };
    // set fonts
    if (titleFont !== undefined && titleFont !== props.titleFont) {
      props.titleFont = titleFont || '';
    }
    if (bodyFont !== undefined && bodyFont !== props.bodyFont) {
      props.bodyFont = bodyFont || '';
    }
    if (chartFont !== undefined && chartFont !== props.chartFont) {
      props.chartFont = chartFont || '';
    }
    // set colors
    if (accentColor !== undefined && accentColor !== props.accentColor) {
      props.accentColor = accentColor || '';
    }
    if (backgroundColor !== undefined && backgroundColor !== props.backgroundColor) {
      props.backgroundColor = backgroundColor || '';
    }

    if (backgroundColorOverride !== undefined && backgroundColorOverride !== props.backgroundColorOverride) {
      props.backgroundColorOverride = backgroundColorOverride || '';
    }
    // it should be safe to call this repeatedly without side-effect occurring
    if (!isSameDesign(props, this.props)) {
      this.props = Object.freeze(props);
      return this.update();
    }
  }

  reset () {
    this.props = Object.freeze({
      titleFont: '',
      bodyFont: '',
      chartFont: '',
      backgroundColor: '',
      backgroundColorOverride: '',
      accentColor: '',
    });
    this.update();
  }

  saveData () {
    // no need to save anything if this is the same as the base theme
    if (isSameDesign(this.props, baseTheme)) {
      return undefined;
    }
    // save only changed values, if any exist
    let changeCount = 0;
    const obj = {};
    for (const [ key, value ] of Object.entries(this.props)) {
      if (value) {
        changeCount++;
        obj[key] = value;
      }
    }
    return changeCount ? obj : undefined;
  }

  async update () {
    const themeProps = {
      ...this.props,
      model: this.model,
    };
    await Model.preconditions;
    // resolve color formulas and ensure valid colors
    themeProps.backgroundColor = optDocBackgroundColor.read(themeProps);
    themeProps.accentColor = optAccentColor.read(themeProps);
    Object.assign(themeProps, this.override);

    let didUpdate = false;
    if (!isSameDesign(this.theme, themeProps)) {
      this.theme = new Theme(themeProps);
      didUpdate = true;
    }
    if (didUpdate || this._chartThemeName !== this.chartTheme.name) {
      // only construct a new theme object when name is set so
      // we don't incur prop changes / unneeded renders
      this.chartTheme = new ChartTheme(this.chartThemeName, {
        foreground: this.theme.color,
        background: this.theme.background,
        font: this.theme.chartFont,
      });
      didUpdate = true;
    }
    return didUpdate;
  }
}
