import { isBrowser } from '@grid-is/browser-utils';

export const canDragUpload = () => {
  if (typeof document !== 'undefined' && isBrowser()) {
    const div = document.createElement('div');
    const ddapi = ('draggable' in div) || ('ondragstart' in div && 'ondrop' in div);
    return ddapi && ('FormData' in window) && ('FileReader' in window);
  }
  return false;
};

export function isDragDataWithFiles (e) {
  if (!e.dataTransfer) {
    return true;
  }
  return Array.from(e.dataTransfer.types)
    .every(d => d === 'Files' || d === 'application/x-moz-file');
}

export function formatSize (size: number) {
  if (!size) {
    return '0 bytes';
  }
  const unit = [ 'bytes', 'kB', 'MB', 'GB', 'TB' ];
  const pow = Math.floor(Math.log10(size) / Math.log10(1024));
  return Math.round(size / 1024 ** pow) + ' ' + unit[pow];
}

export function fileExtension (fileName: string) {
  const index = fileName.lastIndexOf('.');
  return index > 0 ? fileName.slice(index + 1) : '';
}

export function removeFileExtension (fileName: string) {
  const index = fileName.lastIndexOf('.');
  return index > 0 ? fileName.slice(0, index) : fileName;
}
